import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { notification } from 'antd';
import { useModal } from '../../../../context/ModalContext';

/**
 * EducationSave Success modal
 * @param {[type]} props [description]
 */
export function EducationSave(props) {
	// const [show, setShow] = React.useState(false);
	const { openModal } = useModal();

	// const handleCloseEduSave = () => {
	//   if(props.closeMain) props.closeMain();
	//   setShow(false)
	// };
	const handleShowEduSave = () => {
		if (props.EDUmodalData) {
			let errorMessages = [];
			let {
				instituteName,
				courseName,
				specializationName,
				job_type,
				durationFrom,
				durationTo,
				isOnGoing,
				addressLoc,
			} = props.EDUmodalData;
			console.log("🚀 ~ handleShowEduSave ~ props.EDUmodalData:", props.EDUmodalData)
			if (!instituteName)
				errorMessages.push(
					'School/College/University name is required'
				);
			if(!addressLoc) errorMessages.push("Location is required")	
			if (!courseName) errorMessages.push('Degree name is required');
			if (!specializationName)
				errorMessages.push('Field of study is required');
			if (!String(job_type))
				errorMessages.push('Education type is required');
			if (!durationFrom) errorMessages.push('Start year is required');
			if (!isOnGoing && !durationTo)
				errorMessages.push('End year is required');

			if (errorMessages.length > 0) {
				openModal({
					title: 'Validation Error',
					icon: 'fa-times',
					className: 'error-modal',
					buttonText: 'OK',
					title: `Failure Reason: ${errorMessages?.[0]}`,
					type:"error"
				});
				// notification.error({
				// 	message: 'Validation Error',
				// 	description: errorMessages.map((error, index) => (
				// 		<p className="m-0" key={index}>
				// 			{error}
				// 		</p>
				// 	)),
				// });
				return false;
			}
		}
		if (props.clickThis) {
			props.clickThis();
		}
		if (props.resetEducationForm) {
			props.resetEducationForm();
		}
		if (props.closeMain) props.closeMain();
		openModal({
			title: `Education details ${
				props.buttonText === 'Update' ? 'updated' : 'added'
			} successfully!`,
			type: 'success',
		});
	};
	return (
		<>
			<Button onClick={handleShowEduSave} variant="dark" type="submit">
				{props.buttonText ?? 'Save'}
			</Button>

			{/* <Modal
        show={show}
        onHide={handleCloseEduSave}
        centered
        size="md"
        className="save-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <i className="fa fa-check"></i>
          <h2>Education details added successfully</h2>
          <div className="text-right">
            <Button onClick={handleCloseEduSave} variant="dark">
              Ok
            </Button>
          </div>
        </Modal.Body>
      </Modal> */}
		</>
	);
}
