import React, { Fragment } from 'react';
import { Col, Row, Card, Dropdown, Button, Modal, Form, InputGroup, Collapse,Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReferralService from "../../../services/individual/referral";
import { findIndex, join, map } from 'lodash';
import PostService from '../../../services/individual/post';
import { useState, useEffect } from 'react';
import Notify from '../../../Helpers/notify';
import Approve from '../../../Helpers/approve';
import Utils from '../../../Helpers/utils';
import TrackModal from '../../Individual/Components/CommonComponents/trackModal';
import CommonPostService from '../../../services/common/post';
import MatchBadge from './MatchBadge';
import { useModal } from '../../../context/ModalContext';

function IndividualPostCard(props) {

	const [showApplyModal, setShowApplyModal] = useState(false);
	const [showReferModal, setShowReferModal] = useState(false);
	const [activePostId, setActivePostId] = useState('');

	const [successModalText, setSuccessModalText] = useState('');
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [showSelectedConModal, setShowSelectedConModal] = React.useState(false);
	const [activeProfileIndex, setActiveProfileIndex] = useState({});
	const { openModal } = useModal();
	const { post, guard,view,type } = props;

	let basePath = 'individual';
	switch (guard) {
		case 'company':
		case 'corporate': basePath = 'corporate'; break;
		case 'user':
		case 'individual': basePath = 'individual'; break;
		case 'institute': basePath = 'institute'; break;
		default: basePath = 'individual'; break;
	}

	/*
		Props:
			- `post` -> the post object.
			- `guard` -> type of user (individual, company, institute).
			- `type` -> type of post (post, track, refer etc).
			- `view` -> the view where this post will be displayed (wall, explore etc).

		Refer: src\Modules\Common\Components\postComponent\MyPosts.js for guard check logic or other help.
		Styling: src\assets\scss\components\_card.scss (&.card-post - line 237)
	*/

	const { id, title, designation, description, bonus_amount, expiredInDays, created_at_date, closeRemark, job_type, minBonus,visibility,bonusAmountPerVacancy,city,matched_percent,applier } = post;

	const onDirectApplyClick = (e, item) => {
		e.preventDefault();
		// Example usage:
		openModal({
			icon:"fa-question",
			type: 'warning',
			title: 'Are you sure you want to apply for this post?',
			isConfirmation: true,
			onConfirm: () => applyDirectly(item.id),
		});

		// Approve({
		// 	title: 'Direct Apply',
		// 	content: 'Are you sure you want to apply for this post?',
		// 	onOk: () => applyDirectly(item.id)
		// });
	}

	const onApplyClick = (e, item) => {
		e.preventDefault();
		handleApplyModalShow(true, item.id);
	}

	const onReferClick = (e, item) => {
		e.preventDefault();
		handleReferModalShow(true, item.id);
	}

	const getJobType = () => {
		switch (parseInt(job_type)) {
			case 1: return 'Full Time';
			case 2: return 'Part Time';
			case 3: return 'Freelancer';
			case 4: return 'Contractor';
			case 5: return 'Temporary';
			default: return 'N/A';
		}
	}
	const getJobTextFromJobType=(postJob_type)=>{
		switch (parseInt(postJob_type)) {
			case 1: return 'Full Time';
			case 2: return 'Part Time';
			case 3: return 'Freelancer';
			case 4: return 'Contractor';
			case 5: return 'Temporary';
			default: return 'N/A';
		}
	}
	// Simplify the process of formatting numbers and determining experience and salary ranges
	const formatExperienceAndSalary = (post) => {
		const format = (num) => Utils.formatNumber(num || 0); // Default to 0 if undefined or null

		// Format Experience
		const expFrom = format(post.postExperienceFrom);
		const expTo = format(post.postExperienceTo);
		const exp = expTo >= 0 ? `${expFrom} - ${expTo} yrs` : 'N/A';

		// Format Salary
		const salaryFrom = format(post.postSalaryFrom);
		const salaryTo = format(post.postSalaryTo);
		const salary = salaryTo >= 0 ? `${salaryFrom} - ${salaryTo} LPA` : 'N/A';

		// Other details
		const cities = post.postCity;

		return { exp, salary, cities };
	};

	const getPostChainStatusText = status => {
		switch (parseInt(status)) {
			case -2: return 'Rejected';
			case -1: return 'Rejected (by Post Creator)';
			case 0: return 'Pending';
			case 1: return 'Applied';
			case 2: return 'Shortlisted';
			case 3: return 'Accepted';
			default: return 'N/A';
		}
	}

	const handleApplyModalShow = (show, postId) => {
		postId = show ? postId : '';

		setShowApplyModal(show);
		setActivePostId(postId);
	}

	const handleReferModalShow = (show, postId) => {
		postId = show ? postId : '';

		setShowReferModal(show);
		setActivePostId(postId);
	}

	const applyDirectly = async postId => {
		try {
			const { data } = await PostService.ApplyDirectly({ postId });
			if (data.success)
			{
				openModal({
					title: `Application Successfully Sent`,
					type: 'success',
				});
			}
				// Notify.success('Success', 'Applied for the post successfully.');
		} catch (error) {
			console.log(error);
		}
	}

	const handleProfileSelection = (postId, index) => {
		let tmp = {...activeProfileIndex}
		tmp[postId] = index
		setActiveProfileIndex(tmp)
	}

	const onAcceptReferral = (e, chain) => {
		e.preventDefault();
		acceptRejectReferral(chain.postId, chain.id, true);
	}

	const onRejectReferral = (e, chain) => {
		e.preventDefault();
		acceptRejectReferral(chain.postId, chain.id, false);
	}

	const onAskClosePost = (e, item) => {
		e.preventDefault();

		Approve({
			title: 'Close Post',
			content: 'Are you sure you want to close this post?',
			onOk: () => onClosePost(item.id)
		});
	}
	const onClosePost = async (postId) =>{
		console.log({postId});
		try {
			const {data} =  await CommonPostService.closePost({postId:postId, reason: "NA"}, 'user');
			if (data.status)

				Notify.success('Success', 'Post closed successfully.');
		} catch (error) {
			console.log(error)
			// Notify.error('Success', 'Could not closed post.');
		}
	}

	const acceptRejectReferral = async (postId, postChainId, isAccept) => {
		const formData = { postId, postChainId, isAccept };

		try {
			const { data } = await ReferralService.AcceptRejectReferral(formData);
			let message = 'Post rejected.';
			if (isAccept && data.success) {
				message = 'Post accepted successfully!';
			}

			Notify.success('Referral Post', message);
			if(props.callback) props.callback();
		} catch (error) {
			console.log(error);
		}
	}

	function getPostStatusType(){
		let statusText="Viewed";
		if(post.lastViewedAt){
			statusText= "Viewed";
		}
		if(post.userApply){
			statusText= "Applied";
		}
		return statusText;
	}

	function renderVisibility() {
		let visibilityText = "";
		switch (post.visibility) {
			case 1:
				visibilityText = "Explore"
				break;
			case 2:
				visibilityText = "All Connections"
				break;
			case 3:
				visibilityText = "Selected Connections";
				break;
			default:
				visibilityText = "No Connections";
				break;
		}

		return (
			<React.Fragment>
				<Button
					onClick={() => {
						if(post.visibility == 3) {
							setShowSelectedConModal(true);
						}
					}}
					variant="mutual-pics"
					className="btn p-0 border-0 text-left text-lowercase btn-auto"
				>
					<div className="mutualPics pt-0 pb-0">
						{
							post.visibility == 3 &&
							post.selectedConnections &&
							post.selectedConnections.filter((c, i) => i < 5).map((conn, j) => (
								<img key={`selected-conn-profile-pic-${j}`} alt="pic" src={Utils.getUserProfilePhotoUrl(conn.profilePicture, 1)} />
							))
						}
						{visibilityText}
					</div>
				</Button>

				<Modal show={showSelectedConModal} onHide={() => setShowSelectedConModal(false)} centered size="md" className="mutualConnectionModal">
					<Modal.Header closeButton>
						<Row className="align-items-center">
							<Col>
								<Modal.Title>Selected Connections</Modal.Title>
							</Col>
							<Col>
								<InputGroup className="search-input">
									<Form.Control type="text" name="" id="" placeholder="Search connections"></Form.Control>
									<InputGroup.Append>
										<Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
									</InputGroup.Append>
								</InputGroup>
							</Col>
						</Row>
					</Modal.Header>
					<Modal.Body>
						<div className="cards-listing p-0 border-0">
							{
								post.selectedConnections &&
								post.selectedConnections.map((conn, k) => {
									return (
										<Card as={ Link } to={`/${basePath}/profile/user/${conn.id}`} className="userVcard card-horizontal" target="_blank" key={`selected-conn-${k}`}>
											<div className="card-image">
												<img alt="pic" src={Utils.getUserProfilePhotoUrl(conn.profilePicture, 1)} />
											</div>
											<div className="card-content">
												<Card.Header>
													<div className="card-title">
														<h2>{conn.name}</h2>
													</div>
													<div className="card-designation">
														<p><span>{conn.currentWork?.company?.title}</span></p>
														<p><small>{conn.currentWork?.designation?.title}</small></p>
													</div>
												</Card.Header>
											</div>
										</Card>
									)
								})
							}
						</div>
					</Modal.Body>
				</Modal>
			</React.Fragment>
		);
	}

	function renderFooterButtons (cardType){
		return (
			<>
			{cardType=='POST_WALL' ? (
				<>
				{/* { post.showDirectApplyButton ? <Col className="p-0"><Button variant="white">APPLY</Button></Col> : '' } */}
					{post.showApplyButton ? (
						<Col className="p-0">
							<Button variant="white" block onClick={e => onApplyClick(e, post)}>
								APPLY {!post.showDirectApplyButton ? <>{post.applyCount?(`(${post?.applyCount})`):""}</> : <></>}
							</Button>
						</Col>
					) : ('')}
					{ post.showReferButton ?(
						<Col className="p-0">
							<Button variant="white" block onClick={e => onReferClick(e, post)}>REFEROUTE {post.referCount > 0 ? `(${post.referCount})` : ``}</Button>
						</Col>
					) : ('')}

					{post.post_type === 3 ? <Col className="p-0">
						<Button as={Link} block to={`/individual/post/details/${post.id}`} target="_blank" variant="white">COMMENTS {post.totalComments > 0 ? `(${post.totalComments})` : ``}
						</Button>
					</Col> : ''}
				</>
			) : ('')}

			{cardType=='POST_WALL' || cardType=='POST_EXPLORE' ? (
				<>
					<PostReferouteModal postId={activePostId} show={showReferModal} setSuccessShow={setShowSuccessModal} setShow={handleReferModalShow} post={post} setSuccessText={setSuccessModalText} />
					<ApplyModal post={post} postId={activePostId} show={showApplyModal} setSuccessShow={setShowSuccessModal} setShow={handleApplyModalShow} setSuccessText={setSuccessModalText} />
					<SuccessModal text={successModalText} show={showSuccessModal} setShow={setShowSuccessModal} setText={setSuccessModalText} />
				</>
			) : ('')}

			{cardType=='POST_EXPLORE' ? (
				<>
				{(post.status === -1) && props.showAuthUserActions ? <Col className="p-0"><Button block variant="white" className="text-warning font-italic" disabled>{closeRemark || 'Reason Unavailable'}</Button></Col> : ''}

				{(post.status >= 0) && props.showAuthUserActions ? <Col className="p-0"><Button block variant="white" onClick={props.editPost}>Edit Post</Button></Col> : ''}

				{(post.status >= 0) && props.showAuthUserActions ? <Col className="p-0"><Button block as={Link} to={`/individual/post/details/${post.id}`} variant="white" target="_blank">Comments</Button></Col> : ''}

				{props.showAuthUserActions && !post.isDraft ? <Col className="p-0"><Button block as={Link} to={`/individual/home/response-manager/${id}`} variant="white" target="_blank">Responses</Button></Col> : ''}

				{(post.status >= 0) && props.showAuthUserActions && post.isDraft ? <Col className="p-0"><Button block variant="white" type="button">Delete</Button></Col> : ''}

				{post.showDirectApplyButton ? <Col className="p-0"><Button block variant="white" onClick={e => onDirectApplyClick(e, post)}>APPLY</Button></Col> : ''}

				{post.showApplyButton ? <Col className="p-0"><Button block variant="white" onClick={e => onApplyClick(e, post)}>APPLY ({post.applyCount || '0'})</Button></Col> : ''}

				{post.showReferButton ? <Col className="p-0"><Button block variant="white" onClick={e => onReferClick(e, post)}>REFEROUTE {post.referCount > 0 ? `(${post.referCount})` : ``}</Button></Col> : ''}

				</>
			) : ('')}

			{cardType=='TRACK_REFERRED_POST' ? (
				<>
					<Row className="mt-3">
						<Col className="p-0 d-none"><ApplyModal /></Col>
						<Col className="p-0 d-none"><RejectModal /></Col>
						<Col className="p-0">
							<Button onClick={e => onAcceptReferral(e, post)} variant="white" className="btn-block" type="button">APPLY</Button>
						</Col>
						<Col className="p-0">
							<Button onClick={e => onRejectReferral(e, post)} variant="white" className="btn-block" type="button">DENY</Button>
						</Col>
					</Row>
				</>
			) : ('')}

			{cardType=='REFERRAL_RECEIVED' ? (
				<>
				{/* rec ref */}
					<Col className="p-0"><Button block onClick={e => onAcceptReferral(e, post)} type="button" variant="white">APPROVE</Button></Col>
					<Col className="p-0"><Button block onClick={e => onRejectReferral(e, post)} type="button" variant="white">DECLINE</Button></Col>
				</>
			) : ('')}
			{cardType=='POST_MYPOST' ? (
				<>
					{
						post.filterStatus=='ACTIVE' && (
							<>
								<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=shortListed`} variant="white">
									Shortlisted {post.shortlistCount>0 ? `(${post.shortlistCount})` : ('')}
								</Button>
								{/* <Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=selected`} variant="white">
									Selected {post.hiredCount>0 ? `(${post.hiredCount})` : ('')}
								</Button> */}
								<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=comment`} variant="white">
									Comments
								</Button>
							</>
						)
					}
					{
						post.filterStatus=='INACTIVE' && (
							<>
								<Button onClick={e => onAskClosePost(e,post)} variant="white">
									Close Post
								</Button>
							</>
						)
					}
					<Button as={Link} to={`/${basePath}/home/response-manager/${post.id}?tabType=responses`} variant="white">
						Responses {post.responseCount>0 ? `(${post.responseCount})` : ('')}
					</Button>
				</>
			) : ('')}
			</>
		);
	}

	console.log("🚀 ~ IndividualPostCard ~ post:", post)
	const expFrom = post.experienceFrom || 0;
	const expTo = post.experienceTo || 0;
	const exp = expTo >= 0 ? `${parseFloat(expFrom.toString())} - ${parseFloat(expTo.toString())} yrs` : 'N/A';

	const salaryFrom = post.salaryFrom || 0;
	const salaryTo = post.salaryTo || 0;
	const salary = salaryTo >= 0 ? `${parseFloat(salaryFrom.toString())} - ${parseFloat(salaryTo.toString())} LPA` : 'N/A';
	const avatar = Utils.getUserProfilePhotoUrl(post?.creatorDetails?.profilePicture, post?.creatorType);

	let cities = post?.cities?.length ? join(map(post?.cities, 'name'), ', ') : 'N/A';
	if(props.view=='referrals'){
		cities=city;
	}

	// TODO: Add filter.
	if(type=='post' && view=='explore'){
		return (
			<React.Fragment>
				<Col md={6} xl={4} key={post.id}>
					<Card className="postCard bonusPost post-card card-post indCard">
						<div className="card-meta-header d-flex justify-content-between">
							<div className="header-post-meta">
								<span>
									<i className="fa fa-ticket-alt text-orange"></i>
									{visibility === 1 && bonusAmountPerVacancy > 0 ? (<span>₹{bonusAmountPerVacancy}</span>) : ('')}
								</span>
								<span className="px-2">|</span>
								<span>
									{/* <i className="fa fa-clock"></i> */}
									<span>{expiredInDays} Days</span>
								</span>
							</div>
							<div className="header-meta">
								<div className="meta-status">
									<span className="status-icon"><i className=""></i></span>
									{ post.views > 0 ? <span className="status-text text-warning">{post.views} views</span> : null }
								</div>
								<div className="meta-actions">
									{/* <span className="atn-meta"><i className="fa fa-comment"></i></span> */}
									<span className="atn-meta">
										<Dropdown>
											<Dropdown.Toggle className="btn-relative px-1" variant="orange-o">
												<i className="fa fa-ellipsis-v"></i>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
												<Dropdown.Item href="#/action-2">Remove <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
												<Dropdown.Item href="#/action-3">Report <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</span>
								</div>
							</div>
						</div>
						<Link to={`/individual/post/details/${id}`} target="_blank">
							<h2 className="mt-3 d-flex">
								<i className="fa fa-file"></i>
								<div>
									<div className="font-weight-bold text-nowrap text-truncate" style={{width:"250px"}}>{Utils.truncateText(title)}</div>
									<div className="mt-1 text-nowrap text-truncate" style={{width:"250px"}}>{designation}</div>
								</div>
							</h2>
							<p>{Utils.truncateText(description)}</p>
						</Link>
						<Row style={{display:"flex", justifyContent:"space-around",flexWrap:'nowrap'}}>
							<Col style={{paddingLeft:"15px",paddingRight:'auto'}}>
								<div className="fInfo">
									<i className="fa fa-briefcase"></i>{exp}
								</div>
							</Col>
							<Col style={{paddingInline:"5px"}}>
								<div className="fInfo text-nowrap" >
									<i className="fa fa-money-bill-alt"></i>{salary}
								</div>
							</Col>
							<Col style={{paddingRight:"15px",paddingRight:'auto'}}>
								<div className="fInfo" >
									<i className="fa fa-clock"></i>{getJobType()}
								</div>
							</Col>
						</Row>
						<Row className='mt-2'>
							<Col>
								<div className="fInfo text-nowrap text-truncate" style={{width:"300px"}}>
									<i className="fa fa-map-marker-alt"></i>{cities}
								</div>

							</Col>
						</Row>


						<Row>
							<Col><MutualConnection /></Col>
							<Col md={'auto'} className="text-right"><span><i className="fa fa-clock mr-1"></i>{created_at_date}</span></Col>
						</Row>
						<Row className="card-footer-actions institute-posts__footer">
							{renderFooterButtons('POST_EXPLORE')}

						</Row>
						{/* {closeRemark ? <div className="hiredOther">{closeRemark}</div> : ''} */}
					</Card>
				</Col>
			</React.Fragment>
		)
	}
	else if(type=='post' && view=='wall'){
		return (
			<Col md={6} xl={4} key={post.id}>
				<Card className={`card-post indCard ${post.bonus_amount ? 'postCard bonusPost' : 'postCard'}`}>
					<div className="card-meta-header">
						{post.bonus_type != null  ? (
							<div className="header-post-meta">
								{post.creatorType === 1 && post.bonus_amount > 0 && (<>
									₹ {post.bonus_amount} &nbsp;&nbsp;|&nbsp;&nbsp; Immediate
								</>)}
								{ post.creatorType !== 1 && post.minBonus > 0 ? (
									<span>
										<i className="fa fa-ticket-alt mr-2"></i>
										<span>
											₹ {post.minBonus}/- min &nbsp;&nbsp;|&nbsp;&nbsp; {post.expiredInDays} Days
										</span>
									</span>
								) : null }
							</div>

						) : (null)}
						<div className="header-post-meta flex-fill justify-content-end">
							<div className="meta-status">
								<span className="status-icon"><i className=""></i></span>
								<span className="status-text text-warning">{post.views > 0 ? `${post.views} views` : ''}</span>

							</div>
							<div className="meta-actions">
								{/* <span className="atn-meta"><i className="fa fa-comment"></i></span> */}
								<span className="atn-meta">
									<Dropdown>
										<Dropdown.Toggle className="btn-relative px-1" variant="orange-o">
											<i className="fa fa-ellipsis-v"></i>
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
											<Dropdown.Item href="#/action-2">Remove <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
											<Dropdown.Item href="#/action-3">Report <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</span>
							</div>
						</div>
					</div>
					<Row className="m-0 align-items-center mt-3">
						<img alt="pic" src={avatar} className="companyPic" />
						<h2>
							{post?.creatorDetails?.name}
							<small>{post.companyIndustry}</small>
						</h2>
					</Row>
					<Link to={`/individual/post/details/${post.id}`} target="_blank">
						<h2 className="mt-3">
							<div className="font-weight-bold text-nowrap">{Utils.truncateText(post.title)}</div>
							<div className="mt-2">{post.designation}</div>
						</h2>
						<p>{Utils.truncateText(post.description)}</p>
					</Link>
					{post.creatorType!=1 ? (
						<Row>
							<Col>
								<div className="fInfo  text-nowrap">
									<i className="fa fa-briefcase fa-lg"></i>{exp}
								</div>
							</Col>
							<Col style={{paddingInline:"10px"}}>
								<div className="fInfo text-nowrap" style={{width:"95px"}}>
									<i className="fa fa-money-bill-alt fa-lg"></i>{salary}
								</div>
							</Col>

							<Col>
								<div className="fInfo text-nowrap">
									<i className="far fa-clock mr-1 fa-lg"></i>{getJobType()}
								</div>
							</Col>
						</Row>
					) : <Row style={{height:'21px'}} >{''}</Row>
}
					<Row>
						<Col className='my-3'>
							<div className="fInfo text-nowrap text-truncate" style={{width:"300px"}}>
								<i className="fa fa-map-marker-alt fa-lg"></i>{post.city}
							</div>

						</Col>
					</Row>
					<Row>
						<Col md={'auto'}>
							<span><i className="fa fa-eye mr-1"></i><span className="text-orange font-italic">{getPostStatusType()}</span></span>
						</Col>
						<Col className="text-right"><span><i className="fa fa-clock mr-1"></i>{post.created_at_date}</span></Col>
					</Row>
					<Row className="card-footer-actions institute-posts__footer">
						{renderFooterButtons('POST_WALL')}

					</Row>
				</Card>
			</Col>
		)
	}
	else if(type=='appliedPost' && view=='track'){
		let referredBy = {};
		if(post.details.length >= 2){
			referredBy = post.details[post.details.length - 2];
			referredBy = referredBy ? referredBy.user : {};
		}
		const { exp, salary, cities } = formatExperienceAndSalary(post);
		const jobType=getJobTextFromJobType(post?.postJob_type)
		// console.log("referredBy",post);
		return (
			<Col md={6} xl={4} key={post.postChainId}>
				<Card className="postCard card-post pb-2">
					<div className="card-meta-header postStatus" style={{position:'relative',padding:'4px'}}>
						<div className="py-1 d-flex flex-row align-items-center">
							<i className="far fa-clock text-orange mr-1"></i>
							<span className="text-black">Referral To Be Approved</span>
						</div>
						<small>
							<i style={{marginRight:'50px'}} >{post?.postDate_at}</i>
						</small>
						<TrackModal data={post.details} reverse={true} btnClass={"box-19px top-6px"} iconWidth={24} iconHeight={24} modalTitle="TRACK APPLICATION" />
					</div>
					<Row className="m-0 mb-3 mt-3">
						<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${post.postCreatorProfilePicture}`} className="companyPic" />
						<h2>
							{post.postCreatorName}
							<small>
								{ post.postCreatorType == 1 ? post.postUserDesignation : post.postCompanyIndustry }
							</small>
						</h2>
					</Row>
					<Link to={`/individual/post/details/${post.postId}`} target="_blank">
						<p className="mt-0">
							<b className="d-block">{post.postTitle}</b>
							{post.postDescription}
						</p>
					</Link>
					<Row>
						<Col style={{paddingLeft:"15px",paddingRight:'auto'}}>
							<div className="fInfo" >
								<i className="fa fa-briefcase"></i>{exp}
							</div>
						</Col>
						<Col style={{paddingInline:"5px"}}>
							<div className="fInfo">
								<i className="fa fa-money-bill-alt"></i>{salary}
							</div>
						</Col>
						<Col style={{paddingRight:"15px",paddingLeft:'auto'}}>
								<div className="fInfo" >
									<i className="fa fa-clock"></i>{jobType}
								</div>
						</Col>
					</Row>
					<div className="fInfo pt-2 pb-3">
						<i className="fa fa-map-marker-alt"></i>{cities}
					</div>
					<Row>
						<Col>
							{/* <span>
								<span className="badge badge-green">{post.postChainMatched_percent}%</span> Match
							</span> */}
							<MatchBadge match={ post.postChainMatched_percent }/>
						</Col>
						<Col className="text-right"><span><i className="fa fa-clock mr-1"></i>
						<i>{post.postDate_at || post.postCreated_at_date || 'N/A'}</i>
						</span></Col>
					</Row>
				</Card>
			</Col>
		);
	}
	else if(type=='outgoingReferralsPost' && view=='track'){
		const sentTo = post.details.find(chain => chain.status == 2);
		const chainLength=post?.details?.length;
		const status=post?.details[chainLength-1].status ==2 ?"Approved":"Pending"
		return (
			<Col md={6} xl={4} key={post.id}>
				<Card className="postCard card-post pt-2--">
					<div className="card-meta-header justify-content-start postStatus" style={{padding:'5px'}} >
						<small><span className='text-orange ml-2'>To</span></small><img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${sentTo.user.profilePicture}`} className="companyPic" style={{width:'24px',height:'24px'}}/>
						<p className="m-0 ml-1 h-auto">{`${sentTo.user.firstName} ${sentTo.user.lastName}`}</p>
						{/* <i className="far fa-clock mr-1 fa-xs pl-5 ml-3"></i> */}
					<i style={{position:"absolute",top:"3px",right:"45px"}}>
						<span className="text-orange">{status}</span>
					</i>
					</div>

					<TrackModal statusText={status} btnClass={"box-19px top-6px"} iconWidth={24} iconHeight={24} data={post.details} reverse={true} modalTitle="TRACK APPLICATION" />
					<Row className="m-0 mt-3 d-none">
						<img alt="pic" src="/assets/user-1.png" className="companyPic" />
						<h2>John Doe <label>Hr recruiter</label> <small>ABC Softwares Ltd</small></h2>
					</Row>
					<Row className="mt-3 d-none">
						<Col>
							{/* <div className="status">
								<i className="fa fa-clock mr-2"></i>{ getPostChainStatusText(post.status) }
							</div> */}
							<CancelApplicaton/>
						</Col>
						<Col className="text-right">
						<span><i className="fa fa-clock mr-2"></i>{post?.postDate_at || post?.created_at_date || 'N/A'}</span>
						</Col>
					</Row>
					<Row className="m-0 align-items-center mb-3 mt-3">
						<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${post.creatorProfilePicture}`} className="companyPic" />
						<h2>{post.creatorName}<small>{post.companyIndustry}</small></h2>
					</Row>
					<p className="mt-0">
						<b className="d-block">{post.title}</b>
						{post.description}
					</p>
					<Row style={{display:"flex", justifyContent:"space-around",flexWrap:'nowrap'}}>
							<Col style={{paddingLeft:"15px",paddingRight:'auto'}}>
								<div className="fInfo">
									<i className="fa fa-briefcase"></i>{exp}
								</div>
							</Col>
							<Col style={{paddingInline:"5px"}}>
								<div className="fInfo text-nowrap" >
									<i className="fa fa-money-bill-alt"></i>{salary}
								</div>
							</Col>
							<Col style={{paddingRight:"15px",paddingRight:'auto'}}>
								<div className="fInfo" >
									<i className="fa fa-clock"></i>{getJobType()}
								</div>
							</Col>
						</Row>
						<Row className='pt-2 pb-3'>
							<Col>
								<div className="fInfo text-nowrap text-truncate" style={{width:"300px"}}>
									<i className="fa fa-map-marker-alt"></i>{post.city}
								</div>

							</Col>
						</Row>

					<Row>
						<Col>
							<div className="fInfo">
								{/* <span className="badge badge-green">{post.matched_percent || '0'}%</span> Matching Rate */}
								<MatchBadge match={ post.matched_percent }/>
							</div>
						</Col>
						<Col className="text-right">
							<span><i className="fa fa-clock mr-2"></i>{post?.postDate_at || post?.created_at_date || 'N/A'}</span>
						</Col>
					</Row>
					<Row className="card-footer-actions institute-posts__footer">
						<Button as={Link} to={`#`} className="border-top-1" variant="white">WITHDRAW REQUEST</Button>
					</Row>
				</Card>
			</Col>
		);
	}
	else if(type=='gotReferredPost' && view=='track'){
		const cities = post.cities ? join(map(post.cities, 'name'), ', ') : 'N/A';
		let referredBy = post.details[post.details.length - 2];
		referredBy = referredBy ? referredBy.user : {};
		return (
			<Col md={6} xl={4} key={post.id}>
				<Card className="postCard card-post pt-2--">
					<div className="postStatus">
						<i className="text-orange">Referred by</i>
						<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${referredBy.profilePicture}`} className="companyPic" />
						{referredBy.firstName} {referredBy.lastName}
					</div>
					<TrackModal
						data={post.details}
						reverse={false}
						modalTitle="GOT REFERRED"
						modalSubTitle="You are being requested for an application by:"
						showBonus={true}
						bonusAmount={post.bonus_amount}
					/>
					<Row className="m-0 align-items-center mb-3">
						<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${post.creatorProfilePicture}`} className="companyPic" />
						<h2>{post.creatorName}<small>{post.companyIndustry}</small></h2>
					</Row>
					<p>
						<b className="d-block">{post.title}</b>
						{post.description ? <p className="mt-0">{post.description || 'N/A'}</p> : ''}</p>
					<Row>
						<Col>
							<div className="fInfo">
								<i className="fa fa-briefcase"></i>{exp}
							</div>
						</Col>
						<Col style={{paddingInline:"10px"}}>
							<div className="fInfo" style={{width:"105px"}}>
								<i className="fa fa-money-bill-alt"></i>{salary}
							</div>
						</Col>
						<Col>
							<div className="fInfo">
								<i className="far fa-clock mr-1"></i>Full time
							</div>
						</Col>
					</Row>
					<div className="fInfo pt-2 pb-3">
						<i className="fa fa-map-marker-alt"></i>{cities}
					</div>
					<Row>
						<Col>
							<div className="fInfo">
								{/* <span className="badge badge-green">{post.matched_percent || '0'}%</span> Matching Rate */}
								<MatchBadge match={ post.matched_percent } text="Matching Rate"/>
							</div>
						</Col>
						<Col className="text-right">
							<span><i className="fa fa-clock mr-2"></i>{post?.postDate_at || post?.created_at_date || 'N/A'}</span>
						</Col>
					</Row>
					<div>
						{renderFooterButtons('TRACK_REFERRED_POST')}
					</div>
				</Card>
			</Col>
		);
	}
	else if(type=='post' && view=='myPosts'){
		return (
			<Col md={6} xl={4} key={post.id}>
				<Card className="postCard bonusPost card-post">
					<div className="card-meta-header d-flex justify-content-between">
						{
							post.visibility == 4 ?
							<div className="header-title">
								<i className="fa fa-user-tie mr-2"></i>{post.campusVisibility}
							</div>
							:
							<div className="header-title">
								{post.visibility!=1 && post.bonus_amount!=0 && (
									<span>
										<i className="fa fa-coins fa-lg mr-2"></i>
										₹ {post.bonus_amount}/-  <span className="mx-2">|</span>
										{post.bonus_disbursement == 0 ? "Immediate": parseInt(post.bonus_disbursement) * 30 + " Days"}
									</span>
								)}
								{post.visibility === 1 && post.bonusAmountPerVacancy!=0 && (
									<span>
										<i className="fa fa-coins fa-lg mr-2"></i>
										₹ {post.bonusAmountPerVacancy}/- min <span className="mx-2">|</span>
										{post.bonus_disbursement == 0 ? "Immediate": parseInt(post.bonus_disbursement) * 30 + " Days"}
									</span>
								)}
							</div>
						}
						<div className="header-meta">
							<div className="meta-status">
							{post.views > 0 ? (
								<>
								<span className="status-icon"><i className="far fa-eye"></i></span>
								<span className="status-text text-warning">{post.views} views</span>
								</>
							) : ('') }
							</div>
							<div className="meta-actions">
								<span className="atn-meta">
									<Dropdown>
										<Dropdown.Toggle className="btn-relative px-1" variant="orange-o">
											<i className="fa fa-ellipsis-v"></i>
										</Dropdown.Toggle>
										<Dropdown.Menu>
											<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
											<Dropdown.Item href="#/action-2">Remove <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
											<Dropdown.Item href="#/action-3">Report <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>
								</span>
							</div>
						</div>
					</div>
					<div className="post-head mb-2" >
						<Link to={`/${basePath}/post/details/${post.id}`} target="_blank">
							<h2 className="post-title">{Utils.truncateText(post.title)}</h2>
							<div className="post-designation">{post.designation}</div>
							{
								post.description &&
								<div className="post-description three-line-text">
									{post.description}
								</div>
							}
						</Link>
					</div>
					<Row className="meta-info-list">
						<Col>
							<div className="fInfo no-wrap">
								<i className="fa fa-map-marker-alt"></i>{cities}
							</div>
						</Col>
					</Row>
					<Row className="align-items-end post-meta-container pb-3">
						<Col>
							{renderVisibility()}
						</Col>
						<Col className="text-right pl-0">
							<span className="post-time">
								<i className="far fa-clock mr-1"></i>
								<span>{post.created_at_date}</span>
							</span>
						</Col>
					</Row>
					<Row className="card-footer-actions institute-posts__footer">
						{renderFooterButtons('POST_MYPOST')}
					</Row>
				</Card>
			</Col>
		)
	}
	else if(type=='requestsReceived' && view=='referrals'){
		return (
			<Col md={6} xl={4} key={post.id}>
				<Card className="postCard card-post pt-2--">
					<div className="card-meta-header">
						<div className="header-post-meta">
							{post.bonusAmount>0 ? (
								<span>
									<i className="fa fa-ticket-alt pr-3"></i>
									<span>₹ {post.bonusAmount || 0.00}/- min | 30 Days</span>
								</span>
							) : null}
						</div>
						{/* <div>Match <span className="badge badge-green referral-green-badge">{post.matched_percent || 0}%</span></div> */}
						<div><MatchBadge match={ post.matched_percent } textPosition="leading"/></div>
					</div>
					<Row className="m-0 align-items-center mt-3">
						<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${applier.user.profilePicture}`} className="companyPic" />
						<h2>
						<Link target="_blank" className="d-block text-black" to={`/individual/profile/user/${applier.user.id}`}>{applier.user.name}</Link>
							<label>
								{applier?.user?.currentWork?.designation?.title  }
							</label>
							<small>
							{applier?.user?.currentWork?.company?.title}
							</small>
						</h2>
					</Row>
					<hr className="border-dotted my-2" />
					<div className="referred-by">
						<Row className="mb-mds-2">
							<Col md={8}>
								<label className="text-orange">Referred By</label>
							</Col>
							<Col md={4} className="text-right">
								<TrackModal data={post.post_chain} reverse={false} modalTitle="TRACK REFEROUTE" showBonus={true} bonusAmount={post.bonusAmount}/>
							</Col>
						</Row>
						<Link target="_blank" className="d-block" to={`/individual/profile/user/${post.referredBy.user.id}`}>
							<Row className="m-0 align-items-center">
								<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${post.referredBy.user ? post.referredBy.user.profilePicture: ""}`} className="companyPic" />
								<h2>{post.referredBy.user ? post.referredBy.user.name : ""}</h2>
							</Row>
						</Link>
					</div>
					<hr className="border-dotted my-2" />
					<Link target="_blank" className="d-block" to={post.creatorProfileLink}>
						<Row className="m-0 align-items-center mb-3 mt-3">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${post.creatorProfilePicture}`} className="companyPic" />
							<h2>{post.creatorName}<small>{post.companyIndustry}</small></h2>
						</Row>
					</Link>

					<p className="font-weight-bold">{post.title}</p>
					<Row>
						<Col>
							<div className="fInfo text-nowrap">
								<i className="fa fa-briefcase"></i>{exp}
							</div>
						</Col>
						<Col>
							<div className="fInfo text-nowrap">
								<i className="fa fa-money-bill-alt"></i>{salary}
							</div>
						</Col>
						<Col>
							<div className="fInfo text-nowrap">
								<i className="far fa-clock mr-1"></i>Full time
							</div>
						</Col>
						<Col className="mt-2">
							<div className="fInfo text-nowrap">
								<i className="fa fa-map-marker-alt"></i> {post.city || 'N/A'}
							</div>
						</Col>
					</Row>

					<div className="text-right"><span><i className="fa fa-clock mr-2"></i>{post?.postDate_at || post?.created_at_date || 'N/A'}</span></div>
					<Row className="card-footer-actions institute-posts__footer">
						{renderFooterButtons('REFERRAL_RECEIVED')}
					</Row>
				</Card>
			</Col>
		)
	}
	else if(type=='requestsProceed' && view=='referrals'){
		return (
			<Col md={6} xl={4} key={post.id}>
				<Card className="postCard card-post pt-2--">
					<div className="card-meta-header">
						<div className="header-post-meta">
							{post.bonusAmount>0 ? (
								<span>
									<i className="fa fa-ticket-alt pr-3"></i>
									<span>₹ {post.bonusAmount || 0.00}/- min | 30 Days</span>
								</span>
							) : ('')}
						</div>
						{/* <div>Match <span className="badge badge-green referral-green-badge">{post.matched_percent || 0}%</span></div> */}
						<div><MatchBadge match={ post.matched_percent } text="Match" textPosition="leading"/></div>
					</div>
					<Row className="m-0 align-items-center mt-3">
						<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${post.applier.user.profilePicture}`} className="companyPic" />
						<h2>
							{post.applier.user.name}
							<label>
								{post?.applier?.user?.currentWork?.designation?.title}
							</label>
							<small>
								{post?.applier?.user?.currentWork?.company?.title}
							</small>
						</h2>
					</Row>
					<hr className="border-dotted my-2" />
					<div className="referred-by">
						<Row className="mb-mds-2">
							<Col md={8}>
								<label className="text-orange">Request Sent To</label>
							</Col>
							<Col md={4} className="text-right"><TrackModal data={post.post_chain} reverse={false} modalTitle="TRACK REFEROUTE" showBonus={true} bonusAmount={post.bonusAmount} /></Col>
						</Row>
						<Row className="m-0 align-items-center">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${post?.requestSentTo?.user ? post?.requestSentTo?.user?.profilePicture: ""}`} className="companyPic" />
							<h2>{post?.requestSentTo?.user ? post?.requestSentTo?.user?.name : ""}</h2>
						</Row>
					</div>
					<hr className="border-dotted my-2" />
					<Row className="m-0 align-items-center mb-3 mt-3">
						<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${post.creatorProfilePicture}`} className="companyPic" />
						<h2>{post.creatorName}<small>{post.companyIndustry}</small></h2>
					</Row>
					<p className="font-weight-bold">{post.title}</p>
					<Row>
						<Col>
							<div className="fInfo text-nowrap">
								<i className="fa fa-briefcase"></i>{exp}
							</div>
						</Col>
						<Col>
							<div className="fInfo text-nowrap">
								<i className="fa fa-money-bill-alt"></i>{salary}
							</div>
						</Col>
						<Col>
							<div className="fInfo text-nowrap">
								<i className="far fa-clock mr-1"></i>Full time
							</div>
						</Col>
						<Col>
							<div className="fInfo text-nowrap">
								<i className="fa fa-map-marker-alt"></i> {post.city || 'N/A'}
							</div>
						</Col>
					</Row>
					<div className="text-right"><span><i className="fa fa-clock mr-2"></i>{post?.postDate_at || post?.created_at_date || 'N/A'}</span></div>
				</Card>
			</Col>
		);
	}
	else if(type=='profilesReferred' && view=='referrals'){
		return (
			<Col md={4} xl={4} key={`post-${post.postId}`}>
				<Card className="postCard card-post pt-2--">
					<div className="card-meta-header">
						<div className="header-post-meta">
							{post.bonusAmount && post.bonusAmount > 0 ? (
								<span>
									<i className="fa fa-ticket-alt"></i>
									<span>₹ {post.bonusAmount}/- min &nbsp;&nbsp;|&nbsp;&nbsp;30 Days</span>
								</span>
							) : ('')}
						</div>
						<TrackModal
							data={post?.activeProfile.chain || []}
							reverse={false}
							modalTitle="REFEROUTE"
							degree={post?.activeProfile.profile ? post?.activeProfile.profile.degree : ''}
							btnClass="position-relative"
						/>
					</div>
					<Carousel
						interval={null}
						indicators={false}
						activeIndex={activeProfileIndex[post.postId] || 0}
						onSelect={(selectedIndex, e) => {handleProfileSelection(post.postId, selectedIndex)}}>
						{
							post.profilesReferred &&
							post.profilesReferred.map((profileReferred, profileIndex) => {
								let user = profileReferred.profile ? profileReferred.profile.user : {};
								let currentWork = user.currentWork ? user.currentWork : {};
								return (
									<Carousel.Item key={`corosel-item-${profileIndex}`}>
										<Row className="m-0 align-items-center mt-3">
											<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${user.profilePicture}`} className="companyPic" />
											<h2>
												{user.name}
												<label>{currentWork.designation ? currentWork.designation.title : ''}</label>
												<small>{currentWork.company ? currentWork.company.title : ''}</small>
											</h2>
										</Row>
										<Row className="mt-2">
											<Col>
												<div className="status">
													<i className="fa fa-clock mr-2"></i>{getPostChainStatusText(profileReferred.postChainStatus)}
												</div>
											</Col>
											<Col className="text-right">
												<small className="d-block text-orange m-0">Referred:</small>
												<span> <i className="fa fa-clock mr-1"></i><i>{profileReferred.referredAt}</i></span>
											</Col>
										</Row>
									</Carousel.Item>
								)
							})
						}
					</Carousel>
					<hr className="border-dotted my-2" />
					<Row className="m-0 align-items-center mb-3 mt-3">
						<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${post.postCreatorProfilePicture}`} className="companyPic" />
						<h2>
							{post.postCreatorName}
							<small>{post.postCompanyIndustry || post.postUserDesignation}</small>
						</h2>
					</Row>
					<p className="mt-0">
						<b className="d-block">{post.postTitle}</b>
						{post.postDescription}</p>
					<Row>
						<Col>
							<div className="fInfo">
								<i className="fa fa-briefcase"></i>{exp}
							</div>
						</Col>
						<Col>
							<div className="fInfo">
								<i className="fa fa-money-bill-alt"></i>{salary}
							</div>
						</Col>
						<Col>
							<div className="fInfo">
								<i className="fa fa-user mr-1"></i>Vacancies
							</div>
						</Col>
					</Row>
					<div className="fInfo pt-2 pb-3">
						<i className="fa fa-map-marker-alt"></i>{post.postCity}
					</div>
					<div className="text-right mb-3"><span><i className="fa fa-clock mr-2"></i>{post.postCreated_at_date || 'N/A'}</span></div>
				</Card>
			</Col>
		);
	}
	else{
		return (
			<React.Fragment>
				<Col md={6} xl={4}>
					<Card className="postCard bonusPost post-card card-post indCard">

						<div className="card-meta-header d-flex justify-content-between">
							<div className="header-post-meta">

								{visibility !== 1 && minBonus>0 ? (
									<span>
										<i className="fa fa-ticket-alt text-orange"></i>
										<span>₹{minBonus}</span>
									</span>
								) : ('')}
								<i className="fa fa-clock"></i>{expiredInDays || 30} Days
							</div>


							{props.view!='referrals' ? (
								<div className="header-meta">
									<div className="meta-status">
										<span className="status-icon"><i className="far fa-eye"></i></span>
										<span className="status-text">{post.views || 0} views</span>
									</div>
									<div className="meta-actions">
										<span className="atn-meta">
											<Dropdown>
												<Dropdown.Toggle className="btn-relative px-1" variant="orange-o">
													<i className="fa fa-ellipsis-v"></i>
												</Dropdown.Toggle>
												<Dropdown.Menu>
													<Dropdown.Item href="#/action-1">Share<i className="fa fa-share float-right"></i></Dropdown.Item>
													<Dropdown.Item href="#/action-2">Remove <i className="fa fa-times-circle float-right"></i></Dropdown.Item>
													<Dropdown.Item href="#/action-3">Report <i className="fa fa-stop-circle float-right"></i></Dropdown.Item>
												</Dropdown.Menu>
											</Dropdown>
										</span>
									</div>
								</div>
							) : ('')}
						</div>



						<div className="post-head">
							<Link to={`/${basePath}/post/details/${post.id}`} target="_blank">
								<div className="post-head-details">

									<div className="details-content">
										<h2 className="post-title">{Utils.truncateText(post.title)}</h2>
										<div className="post-designation">{post.designation}</div>
									</div>
								</div>
								{
									post.description &&
									<div className="post-description">
										{Utils.truncateText(post.description)}
									</div>
								}
							</Link>
						</div>
						<Row>
							<Col md={'auto'} className="text-right"><span><i className="fa fa-clock mr-1"></i>{created_at_date}</span></Col>
						</Row>
					</Card>
				</Col>
				<PostReferouteModal post={post} postId={activePostId} show={showReferModal} setSuccessShow={setShowSuccessModal} setShow={handleReferModalShow} setSuccessText={setSuccessModalText} />
				<ApplyModal post={post} postId={activePostId} show={showApplyModal} setSuccessShow={setShowSuccessModal} setShow={handleApplyModalShow} setSuccessText={setSuccessModalText} />
				<SuccessModal text={successModalText} show={showSuccessModal} setShow={setShowSuccessModal} setText={setSuccessModalText} />
			</React.Fragment>
		)
	}

}

IndividualPostCard.defaultProps = {
	post: {},
	view: 'wall',
	type: 'post',
	guard: 'individual',
}

/* modals */

function ApplyModal(props) {
	const { post } = props
	const [busy, setBusy] = useState(true);
	const [applying, setApplying] = useState(false);

	const [data, setData] = useState([]);
	const [currentPostPr, setCurrentPostPr] = useState(0);
	const [refers, setRefers] = useState([]);

	const onSubmit = e => {
		e.preventDefault();
		submitForm();
	}

	const getReferralChain = async () => {
		if (!props.show || !props.postId) return;

		const params = {
			postId: props.postId,
			start: 0,
			length: 50,
			search: '',
		}

		try {
			setBusy(true);
			const { data } = await ReferralService.GetPostReferrals(params);
			setData(data.data);
			setCurrentPostPr(data.matchPercentage || 0);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	// const changeRefers = item => {
	// 	let iRefers = [...refers];
	// 	const referIndex = findIndex(iRefers, { postChainId: item.postChainId });

	// 	if (referIndex >= 0) {
	// 		iRefers.splice(referIndex, 1);
	// 		setRefers(iRefers);
	// 		return;
	// 	}else if (iRefers.length > 0) {
	// 		iRefers = []
	// 	}

	// 	const refer = {
	// 		userId: item.userId,
	// 		postChainId: item.postChainId
	// 	}

	// 	iRefers.push(refer);
	// 	setRefers(iRefers);
	// }

	const handleChainSelection = (chainDetails, checked) => {
		// console.log("Chain details:", chainDetails);
		const applyThroughUserId = chainDetails.chain[0].userId
		let iRefers = [...refers];
		const referIndex = findIndex(iRefers, { userId: applyThroughUserId });
		if (referIndex >= 0) {
			if (checked) {
				iRefers[referIndex].postChainId = chainDetails.postChainId;
			}else{
				iRefers.splice(referIndex, 1);
			}
			setRefers(iRefers);
			return;
		}

		const refer = {
			userId: applyThroughUserId,
			postChainId: chainDetails.postChainId
		}

		iRefers.push(refer);
		setRefers(iRefers);
	}

	const hideModal = () => {
		if (props.setShow)
			props.setShow(false);
			setRefers([]);
	}

	const submitForm = async () => {
		// If cannot apply directly then 'refers' is required
		if (!post.showDirectApplyButton && !refers.length) {
			return;
		}

		let formData = {
			postId: props.postId,
		}

		let api;
		if (!post.showDirectApplyButton) {
			// If cannot apply directly then 'refers' is required
			formData.refers = refers

			// Send referral request
			api = ReferralService.SendPostReferrals
		}else{
			// Apply directly
			api = PostService.ApplyDirectly
		}


		try {
			setApplying(true);
			const { data } = await api(formData);
			if (!data.success) return;
			setData([]);
			setRefers([]);
			hideModal();
			if (props.setSuccessShow) props.setSuccessShow(true);
			setApplying(false);
		} catch (error) {
			console.log(error);
			setApplying(false);
		}
	}

	useEffect(() => {
		if (!post.showDirectApplyButton) {
			getReferralChain();
		}
		setCurrentPostPr(0);
	}, [props.show]);

	const renderItems = () => {
		if (busy) return <p className="text-muted text-center m-0 p-2">Loading...</p>;
		if (!busy && !data.length) return <p className="text-muted text-center m-0 p-2">No referrals found.</p>;

		return map(data, item => {
			const itemData = {
				postId: item.postId,
				postChainId: item.postChainId,
				maxDegree: item.maxDegree,
				postChainStatus: item.postChainStatus,
				userId: item.applyingThroughUserId,
				name: item.applyingThroughUserName,
				profilePicture: item.applyingThroughUserProfilePicture,
				matchPercentage: item?.matchPercentage,
				showChainMatchPercentage:false,
				// chainedConnections: item.applyThroughChain,
				chains: item.chains,
			}

			return <ChainCollapse handleChainSelection={handleChainSelection} selectedChains={refers} chainType="apply" data={itemData} key={item.postChainId} />;
		});
	}

	const renderSelectedChain = () => {
		if (refers.length == 0) {
			return null;
		}
		const selectedChain = data.find(pc => pc.postChainId == refers[0].postChainId)
		if (!selectedChain) {
			return null;
		}
		return (
			<p className="text-orange  mb-0" style={{ fontSize: '14px' }}>
				Selected <span className="text-primary">{selectedChain.applyingThroughUserName}</span> To Process Your Application
			</p>
		)
	}

	return (
		<Modal show={props.show} onHide={hideModal} centered backdrop="static" scrollable={true} size="lg" className="apply-modal">
			<form onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>APPLY<i className="text-orange ml-2 font-weight-normal" style={{ fontSize: '14px' }}>via any 1 network per post</i></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="sub-p my-2 py-2">
						Your matching rate to this requirement is <span className="badge badge-green">{currentPostPr}%</span>
					</p>
					{
						post && !post.showDirectApplyButton ?
						<>
							<p className="font-italic">Below are suggested connections through whom you can process a referral for your application through any 1 connection (or chain) per 24 hours.</p>
							<Row className="align-items-center my-3">
								<Col md={4} className="border-right pr-md-2">
									<h1 className="m-0">Apply via ({data.length})</h1>
								</Col>
								<Col md={'auto'} >
									<InputGroup className="search-input">
										<Form.Control type="text" name="" id="" placeholder="Search your connections"></Form.Control>
										<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
										</InputGroup.Append>
									</InputGroup>
								</Col>
							</Row>
							<Card className="scroll-y">
								{renderItems()}
							</Card>
						</>
						: ''
					}
				</Modal.Body>
				<Modal.Footer className="justify-content-between pt-0 align-items-center flex-nowrap">
					<div>
					{renderSelectedChain()}
					</div>
					<Button variant="dark" type="submit" disabled={ (post && !post.showDirectApplyButton && !refers.length) || applying}>{applying ? 'Applying...' : 'APPLY NOW'}</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

// Success Modal
function SuccessModal(props) {
	const { show, text } = props;

	const onHide = () => {
		if (props.setShow) props.setShow(false);
		if (props.setText) props.setText('');
	}

	return (
		<Modal show={show} onHide={onHide} centered size="md" className="save-modal">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<i className="fa fa-check"></i>
				<h2>{text || 'You have applied for the post successfully'}</h2>
				<div className="text-right">
					<Button onClick={onHide} variant="dark">Ok</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

/* chain collapse */
function ChainCollapse(props) {
	const [open, setOpen] = React.useState(false);
	const { data, selectedChains, chainType } = props;
	const [checkBoxChecked, toggleCheckBox] = React.useState(false);
	const handleChainSelection = (chainDetails, isChecked) => {
		toggleCheckBox(!checkBoxChecked);
		if (props.handleChainSelection) {
			props.handleChainSelection(chainDetails, isChecked);
		}
	}

	const handleCheckBoxChange = e => {
		toggleCheckBox(e.target.checked);
		if (data.chains && data.chains.length > 0) {
			handleChainSelection(data.chains[0], e.target.checked);
		}
	}

	const chainIdField = chainType == "apply" ? "postChainId" : "chainId";
	return (
		<>
			<Button
				onClick={() => setOpen(!open)}
				aria-controls="ChainCollapse-1"
				aria-expanded={open}
				className="btn-collapse btn-auto">
				<Row className="flex-fill align-items-center justify-content-between">
					<Col md={'auto'} className="d-flex flex-row align-items-center flex-fill">
						<Form.Check
							// custom
							type="checkbox"
							// id={`chain_${data.postChainId}`}
							// name="postChainId"
							// value={data.postChainId}
							// label=""
							checked={checkBoxChecked}
							onChange={handleCheckBoxChange}
							disabled={ chainType === "apply" && selectedChains.length >=3 && !checkBoxChecked  }
						/>
						{/* <input type="checkbox" checked={isChecked} onClick={(e) => toggleChecked(!isChecked)} /> */}
						<span className="d-flex flex-row align-items-center ml-2">
							<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${data.profilePicture}`} />
							<span>{data.name}</span>
						</span>
					</Col>
					<Col>
						{
							data.showChainMatchPercentage ? <MatchBadge match={ data.matchPercentage } text="Matching Rate"/> : null
						}
					</Col>
					<Col md={1}>
						<Button as="span" variant="secondary" className="btn-rounded"><i className="fa fa-users"></i></Button>
					</Col>
					<Col md={1}>
						<div className="no">{data.maxDegree}
                   			<svg width="30" height="30" viewBox="0 0 30 30">
								<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
							</svg>
						</div>
					</Col>
				</Row>
			</Button>
			<Collapse in={open}>
				<div id="ChainCollapse-1">
					{
						(typeof data.chains != typeof undefined && data.chains != null && data.chains.length > 0) ?
						data.chains.map((chainDetails, index) => (
							<div className="refered-list" key={`chain-Details-${chainDetails.postChainId}`}>
								<div className="item">
									<Form.Group className="mb-0">
										<div key={`radio_chain_${data.postChainId}`}>
											<Form.Check
												// custom
												type="radio"
												id="ref"
												label=""
												name={`${data.userId}_radio`}
												value={chainDetails.postChainId}
												onChange={() => handleChainSelection(chainDetails, true)}
												// disabled={!checkBoxChecked}
												checked={selectedChains.find(sc => sc.userId == data.userId && sc[chainIdField] == chainDetails.postChainId) != null}
											/>
										</div>
									</Form.Group>
								</div>
								{
									chainDetails.chain && chainDetails.chain.length > 0 ?
									chainDetails.chain.map((connection, j) => (
										<div key={`chain-member-${connection.userId}`}>
											<div className="item">
												{connection.status===1 && <i className="fa fa-check"></i>}
												{
													<Link target="_blank" className="d-block" to={`/individual/profile/user/${connection.userId}`}>
														<img alt="pic" src={`${process.env.REACT_APP_API_PUBLIC_URL}/${connection.profilePicture}`} />
						{/* <img alt="pic" src="https://via.placeholder.com/200" className="" /> */}

													<label>{connection.userName}</label>
													</Link>

												}
											</div>
											{
												j < (chainDetails.chain.length - 1) ?
													<div className="item arrow">
														<img src="/assets/long-arrow-right.svg" alt="" />
													</div>
												: ''
											}

										</div>
									))
									: ''
								}
							</div>
						))
						: ''
					}
				</div>
			</Collapse>
		</>
	);
}

/* mutual  modal */
/* mutual connection modal */
function MutualConnection() {
	const [show, setShow] = React.useState(false);

	const handleCloseMutual = () => setShow(false);
	const handleShowMutual = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowMutual} variant="" className="btn bg-white p-0 border-0 text-left text-lowercase">
				<div className="mutualPics pt-0 pb-0">
					<img alt="pic" src="/assets/user-1.png" />
					<img alt="pic" src="/assets/user-2.png" />
					<img alt="pic" src="/assets/user-3.png" />
					<em className="pl-2">All Connections</em>
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseMutual} centered size="md" className="mutualConnectionModal">
				<Modal.Header closeButton>
					<Modal.Title>Mutual connections</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="UserList">
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-1.png" />
							<div>
								Mario Speedwagon
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>
						<div className="item d-flex">
							<img alt="pic" src="/assets/user-2.png" />
							<div>
								Anna Sthesia
								<span>UI developer</span>
								<small>ABC Softwares</small>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

/* cancel-request */
/* applied success modal */
function CancelApplicaton() {
	const [show, setShow] = React.useState(false);

	const handleCloseApplication = () => setShow(false);
	const handleShowApplication = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowApplication} variant="orange-o" className="btn-rounded ml-2"><i className="fa fa-times"></i></Button>
			<Modal show={show} onHide={handleCloseApplication} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-exclamation"></i>
					<h2>Are you sure you want to cancel the application request sent?</h2>
					<div className="text-center">
						<Button onClick={handleCloseApplication} variant="light" className="mr-2">No</Button>
						<Button onClick={handleCloseApplication} variant="dark">Yes</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

/* Reject modal */
function RejectModal() {
	const [show, setShow] = React.useState(false);

	const handleCloseReject = () => setShow(false);
	const handleShowReject = () => setShow(true);

	return (
		<>
			<Button onClick={handleShowReject} variant="white" type="submit">Not interested</Button>

			<Modal show={show} onHide={handleCloseReject} centered size="md" className="save-modal">
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body>
					<i className="fa fa-times"></i>
					<h2>Rejected</h2>
					<div className="text-right">
						<Button onClick={handleCloseReject} variant="dark">Ok</Button>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}

/* Filters
const RequirementsFilters = () => {
	const [designationOpen, setDesignationOpen] = useState(false);
	const [industryOpen, setIndustryOpen] = useState(false);

	const value = [60, 100];
	const range = { min: 0, max: 100 };
	const pips = {
		values: [10, 20, 30, 40, 50, 60, 70, 80, 90]
	};
	const expValue = [1];
	const expRange = { min: 0, max: 10 };
	const expPips = {
		values: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
	};
	const bonValue = [500];
	const bonRange = { min: 100, max: 5000 };
	const bonPips = {
		values: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000]
	};

	return (
		<div className="filter-card-sticky-top">
			<div className="pf-found pt-0">
				<span>Profiles Found</span> 50,461
			</div>
			<Card className="filter-card p-3 scroll-y">
				<Form.Group>
					<Form.Label>Post type</Form.Label>
					{['checkbox'].map((type) => (
						<div key={`custom-${type}`}>
							<Form.Check
								custom
								type={type}
								id="freshness"
								name="freshness"
								label="Freshness"
							/>
						</div>
					))}
				</Form.Group>
				<Form.Group>
					<Form.Label>Post type</Form.Label>
					{['checkbox'].map((type) => (
						<div key={`custom-${type}`}>
							<Form.Check
								custom
								type={type}
								id="company"
								name="company"
								label="Company"
							/>
							<Form.Check
								custom
								type={type}
								id="institute"
								name="institute"
								label="Institute"
							/>
						</div>
					))}
				</Form.Group>
				<Form.Group>
					<Form.Label>Requirement Type</Form.Label>
					{['checkbox'].map((type) => (
						<div key={`custom-${type}`}>
							<Form.Check
								custom
								type={type}
								id="full_time"
								name="full_time"
								label="Full Time"
							/>
							<Form.Check
								custom
								type={type}
								id="part_time"
								name="part_time"
								label="Part Time"
							/>
							<Form.Check
								custom
								type={type}
								id="internship"
								name="internship"
								label="Internship"
							/>
							<Form.Check
								custom
								type={type}
								id="vendor"
								name="vendor"
								label="Vendor"
							/>
							<Form.Check
								custom
								type={type}
								id="supplier"
								name="supplier"
								label="Supplier"
							/>
							<Form.Check
								custom
								type={type}
								id="partner"
								name="partner"
								label="Partner"
							/>
							<Form.Check
								custom
								type={type}
								id="other"
								name="other"
								label="Other"
							/>
						</div>
					))}
				</Form.Group>
				<Form.Group>
					<Form.Label>Designation</Form.Label>
					{['checkbox'].map((type) => (
						<div key={`custom-${type}`}>
							<Form.Check
								custom
								type={type}
								id="ui_ux_developer"
								name="ui_ux_developer"
								label="UI/UX developer"
							/>
							<Form.Check
								custom
								type={type}
								id="manager"
								name="manager"
								label="Manager"
							/>
							<Form.Check
								custom
								type={type}
								id="full_Stack_developer"
								name="full_Stack_developer"
								label="Full Stack Developer"
							/>
						</div>
					))}
					<Collapse in={designationOpen}>
						<div>
							{['checkbox'].map((type) => (
								<div key={`custom-${type}`}>
									<Form.Check
										custom
										type={type}
										id="laravel_developer"
										name="laravel_developer"
										label="Laravel Developer"
									/>
									<Form.Check
										custom
										type={type}
										id="meas_stack_developer"
										name="meas_stack_developer"
										label="Meas Stack Developer"
									/>
									<Form.Check
										custom
										type={type}
										id="python_developer"
										name="python_developer"
										label="Python Developer"
									/>
								</div>
							))}
						</div>
					</Collapse>
				</Form.Group>
				<div className="text-right">
					<Button variant="view-all"
						onClick={() => { setDesignationOpen(!designationOpen) }}>
						{(function () {
							if (designationOpen) {
								return ('View Less')
							} else {
								return ('View More')
							}
						})()}
					</Button>
				</div>
				<Form.Group>
					<Form.Label>Bonus Type</Form.Label>
					{['checkbox'].map((type) => (
						<div key={`custom-${type}`}>
							<Form.Check
								custom
								type={type}
								id="cash"
								name="cash"
								label="Cash"
							/>
							<Form.Check
								custom
								type={type}
								id="voucher"
								name="voucher"
								label="Voucher"
							/>
						</div>
					))}
				</Form.Group>
				<Form.Group className="pr-4">
					<Form.Label>Bonus Amount</Form.Label>
					<Nouislider start={bonValue} range={bonRange} values={bonPips} tooltips={true} />
					<Row>
						<Col><label>1</label></Col>
						<Col className="text-right"><label>100</label></Col>
					</Row>
				</Form.Group>
				<Form.Group className="pr-4">
					<Form.Label>Experience</Form.Label>
					<Nouislider start={expValue} range={expRange} steps={10} values={expPips} tooltips={true} />
					<Row>
						<Col><label>1</label></Col>
						<Col className="text-right"><label>100</label></Col>
					</Row>
				</Form.Group>
				<Form.Group className="pr-4">
					<Form.Label>Salary</Form.Label>
					<Nouislider start={value} range={range} values={pips} tooltips={true} />
					<Row>
						<Col><label>1</label></Col>
						<Col className="text-right"><label>100</label></Col>
					</Row>
				</Form.Group>
				<Form.Group>
					<Form.Label>Location</Form.Label>
					{['checkbox'].map((type) => (
						<div key={`custom-${type}`}>
							<Form.Check
								custom
								type={type}
								id="mumbai"
								name="mumbai"
								label="Mumbai"
							/>
							<Form.Check
								custom
								type={type}
								id="pune"
								name="pune"
								label="Pune"
							/>
							<Form.Check
								custom
								type={type}
								id="banglore"
								name="banglore"
								label="Banglore"
							/>
							<Form.Check
								custom
								type={type}
								id="delhi"
								name="delhi"
								label="Delhi"
							/>
							<Form.Check
								custom
								type={type}
								id="chennai"
								name="chennai"
								label="Chennai"
							/>
						</div>
					))}
				</Form.Group>
				<Form.Group>
					<Form.Label>Education</Form.Label>
					{['checkbox'].map((type) => (
						<div key={`custom-${type}`}>
							<Form.Check
								custom
								type={type}
								id="be_it"
								name="be_it"
								label="BE IT"
							/>
							<Form.Check
								custom
								type={type}
								id="mcs"
								name="mcs"
								label="MCs"
							/>
							<Form.Check
								custom
								type={type}
								id="me"
								name="me"
								label="ME"
							/>
							<Form.Check
								custom
								type={type}
								id="under_graduate"
								name="under_graduate"
								label="Under Graduate"
							/>
						</div>
					))}
				</Form.Group>
				<Form.Group>
					<Form.Label>Industry</Form.Label>
					{['checkbox'].map((type) => (
						<div key={`custom-${type}`}>
							<Form.Check
								custom
								type={type}
								id="information_technology"
								name="information_technology"
								label="Information technology"
							/>
							<Form.Check
								custom
								type={type}
								id="farmaciticle"
								name="farmaciticle"
								label="Fresher"
							/>
							<Form.Check
								custom
								type={type}
								id="financial_sector"
								name="financial_sector"
								label="Financial Sector"
							/>
						</div>
					))}
					<Collapse in={industryOpen}>
						<div>
							{['checkbox'].map((type) => (
								<div key={`custom-${type}`}>
									<Form.Check
										custom
										type={type}
										id="information_technology"
										name="information_technology"
										label="Information technology"
									/>
									<Form.Check
										custom
										type={type}
										id="farmaciticle"
										name="farmaciticle"
										label="Fresher"
									/>
									<Form.Check
										custom
										type={type}
										id="financial_sector"
										name="financial_sector"
										label="Financial Sector"
									/>
								</div>
							))}
						</div>
					</Collapse>
				</Form.Group>
				<div className="text-right">
					<Button variant="view-all"
						onClick={() => { setIndustryOpen(!industryOpen) }}>
						{(function () {
							if (industryOpen) {
								return ('View Less')
							} else {
								return ('View More')
							}
						})()}
					</Button>
				</div>
			</Card>
		</div>
	);
} */

// Post Referoute Modal

const PostReferouteModal = props => {
	const [busy, setBusy] = useState(true);
	const [applying, setApplying] = useState(false);

	const [data, setData] = useState([]);
	const [refers, setRefers] = useState([]);

	const onSubmit = e => {
		e.preventDefault();
		submitForm();
	}

	let {post} = props;
	post = post || {}

	const getPostRefers = async () => {
		if (!props.show || !props.postId) return;

		const params = {
			postId: props.postId,
			start: 0,
			length: 50,
			search: '',
			isRecommended: 1
		}

		try {
			setBusy(true);
			const { data } = await ReferralService.GetPostRefers(params);
			setData(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	// const changeRefers = item => {
	// 	const iRefers = [...refers];
	// 	const referIndex = findIndex(iRefers, { userId: item.userId });

	// 	if (referIndex >= 0) {
	// 		iRefers.splice(referIndex, 1);
	// 		setRefers(iRefers);
	// 		return;
	// 	}

	// 	const refer = {
	// 		userId: item.userId,
	// 		chainId: item.postChainId
	// 	}

	// 	iRefers.push(refer);
	// 	setRefers(iRefers);
	// }

	const handleChainSelection = (chainDetails, checked) => {
		// console.log("Chain details:", chainDetails);
		const referringToUserId = chainDetails.chain[chainDetails.chain.length - 1].userId
		let iRefers = [...refers];
		const referIndex = findIndex(iRefers, { userId: referringToUserId });
		if (referIndex >= 0) {
			if (checked) {
				iRefers[referIndex].chainId = chainDetails.postChainId;
			}else{
				iRefers.splice(referIndex, 1);
			}
			setRefers(iRefers);
			return;
		}

		const refer = {
			userId: referringToUserId,
			chainId: chainDetails.postChainId
		}

		iRefers.push(refer);
		setRefers(iRefers);
	}

	const hideModal = () => {
		if (props.setShow) props.setShow(false);
	}

	const submitForm = async () => {
		if (!refers.length) return;

		const formData = {
			postId: props.postId,
			refers,
		}

		try {
			setApplying(true);
			const { data } = await ReferralService.SendPostRefers(formData);
			if (!data.success) return;
			setData([]);
			setRefers([]);
			hideModal();
			if (props.setSuccessText) props.setSuccessText('Users referred successfully!');
			if (props.setSuccessShow) props.setSuccessShow(true);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getPostRefers();
	}, [props.show]);

	const renderItems = () => {
		if (busy) return <p className="text-muted text-center m-0 p-2">Loading...</p>;
		if (!busy && !data.length) return <p className="text-muted text-center m-0 p-2">No referrals found.</p>;
		return map(data, item => {
			const itemData = {
				postId: item.postId,
				postChainId: item.postChainId,
				maxDegree: item.referringToDegree,
				postChainStatus: item.postChainStatus,
				userId: item.referringToUserId,
				name: item.referringToUserName,
				profilePicture: item.referringToProfilePicture,
				matchPercentage: item.matchPercentage,
				showChainMatchPercentage:true,
				// chainedConnections: item.referThroughChain,
				chains: item.chains
			}

			return <ChainCollapse chainType="refer" handleChainSelection={handleChainSelection} selectedChains={refers} data={itemData} key={item.referringToUserId} />;
		});
	}
	let selectedUsers = [];
	let selectedChains = [];
	for (let i = 0; i < refers.length; i++) {
		if (!selectedUsers.includes(refers[i].userId)) {
			selectedUsers.push(refers[i].userId)
		}

		if (!selectedChains.includes(refers[i].chainId)) {
			selectedChains.push(refers[i].chainId)
		}
	}

	return (
		<Modal as="form" onSubmit={ onSubmit } show={props.show} onHide={hideModal} backdrop="static" centered size="lg" className="apply-modal" scrollable={true}>
			{/* <form onSubmit={onSubmit}> */}
				<Modal.Header className="align-items-center" closeButton>
					<Modal.Title>REFEROUTE<i className="text-orange ml-2 mt-3 font-weight-normal" style={{ 'fontSize': '12px' }}>any 3 referral per post</i></Modal.Title>
					{/* <div className="d-flex justify-content-end"><i className="fa fa-ticket-alt text-orange "></i>₹ 500/-</div> */}  {/* need to be positioned */}
				</Modal.Header>
				<Modal.Body className="pt-0 no-scroll-x">
					{
						<>
							{post.bonusDistribution && post.bonusDistribution.length > 1 && (
								<>
									<p>The referoute process will be completed once such referral is approved by the entire chain and subject to the selection of the referred profile. Your earning will be as per the bonus disbursement timeline defined in the post. Know more</p>
									<p className="font-italic">Below is your bonus earning at each degree and suggested matches for you to refer.</p>
								</>
							)}

							<div className={`${post.bonusDistribution && post.bonusDistribution.length > 1 ? "deg" : ""} d-md-flex mt-2`}>

								{post.bonusDistribution && post.bonusDistribution.length > 1 && post.bonusDistribution.map((distAmount, i) => {
									return (
										<React.Fragment key={`bonus-for-degree-${i}`}>
											<div className="item col-md">
												<div className="no"> {i + 1}
													<svg width="30" height="30" viewBox="0 0 30 30">
														<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="30" strokeDashoffset="1" />1
													</svg>
												</div>
												<label>₹ {distAmount}</label>
											</div>
											{
												i < (post.bonusDistribution.length - 1) &&
												<div className="item col-md">
													<div className="dash"></div>
												</div>
											}
										</React.Fragment>
									)
								})}
							</div>
						</>
					}
					<Row className="align-items-center my-3">
						<Col md={'auto'} className="border-right pr-md-2">
							<h1 className="m-0">Suggested matches ({data.length})</h1>
						</Col>
						<Col md={4}>
							<InputGroup className="search-input">
								<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Col>
					</Row>
					<Card>
						{renderItems()}
					</Card>
					<h1 className="mt-2 font-italic text-muted">
						<small>
							{selectedUsers.length} profile{selectedUsers.length > 1 ? "s": ""} and {selectedChains.length} chain{selectedChains.length > 1 ? "s": ""} selected
						</small>
					</h1>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" type="submit" disabled={!refers.length || applying}>{applying ? 'Please Wait...' : 'Referoute'}</Button>
				</Modal.Footer>
			{/* </form> */}
		</Modal>
	);
}

/* ----------  Exports  ---------- */

export default IndividualPostCard;
export {
	ApplyModal,
	SuccessModal,
	ChainCollapse,
	PostReferouteModal
}
