import React, { useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useModal } from '../../../../context/ModalContext';

const GlobalModal = () => {
	const { showModal, modalContent, closeModal } = useModal();

	useEffect(() => {
		if (
			showModal &&
			!modalContent?.content &&
			modalContent?.type === 'success'
		) {
			const timer = setTimeout(() => {
				closeModal();
			}, 3000);
			return () => clearTimeout(timer);
		}
		if (modalContent?.type === 'warning') {
			const warningTimer = setTimeout(() => {
				closeModal();
			}, 120000);
			return () => clearTimeout(warningTimer);
		}
		if(modalContent?.type === 'error' && modalContent?.setTimeout)
		{
			const errorTimer = setTimeout(() => {
				closeModal();
			}, 3000);
			return () => clearTimeout(errorTimer);
		}
	}, [showModal, closeModal, modalContent]);

	return (
		<Modal
			show={showModal}
			onHide={closeModal}
			centered
			size="md"
			className={`global-modal ${modalContent?.className || ''}`}
		>
			<Modal.Body>
				{modalContent ? (
					<Modal.Body>
						{modalContent?.type === 'error' && (
							<h2 className="error-heading">FIALURE</h2>
						)}
						<i
							className={`fa ${modalContent.icon || 'fa-check'}`}
						></i>
						<h2 className={`modal-title ${modalContent?.type}`}>
							{modalContent?.title}
						</h2>
						{modalContent.isConfirmation ? (
							<div className="text-center mt-4">
								<Button
									variant="dark-o"
									onClick={closeModal}
									className="mr-3 btn-preview"
								>
									No
								</Button>
								<Button
									variant="dark"
									className="btn-save"
									onClick={modalContent.onConfirm}
								>
									Yes
								</Button>
							</div>
						) : (
							modalContent.buttonText && (
								<div className="text-center">
									<Button
										variant="dark"
										onClick={closeModal}
										className="modal-button"
									>
										{modalContent.buttonText}
									</Button>
								</div>
							)
						)}
					</Modal.Body>
				) : null}
			</Modal.Body>
		</Modal>
	);
};

export default GlobalModal;
