import React from 'react'
import {
	Row,
	Card
} from "react-bootstrap";
import WorkExperienceEditModal from "./WorkExpereienceEditModal";
import Utils from "../../../../Helpers/utils"


function WorkExperienceCard(props) {
	const showPreview = props.showPreview === undefined ? true : props.showPreview;
	// console.log("showPreview::", props)
	const { company, designation, isOnGoing, industry, is_current, job_type,jobType, location, duration_from_month, duration_from_year, duration_to_month, duration_to_year, description, city, state, country, locality,durationFrom,durationTo } = props.weItem;
	console.log("🚀 ~ WorkExperienceCard ~  props.weItem:",  props.weItem)
	console.log("🚀 ~ WorkExperienceCard ~ job_type,jobType:", job_type,jobType,showPreview)
	const fromDate = new Date(`${duration_from_year}-${duration_from_month}`);
	const fromMonth = fromDate.toLocaleString('default', { month: 'long' });
	let endDate = "";
	if (duration_to_year !== null && isOnGoing === false) {
		const toDate = new Date(`${duration_to_year}-${duration_to_month}`);
		const toMonth = toDate.toLocaleString('default', { month: 'long' });
		endDate = toMonth + " " + duration_to_year;
	} else {
		endDate = "Present";
	}
	const [show, setShow] = React.useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const onDeleteItem = e => {
		e.preventDefault();
		if(props.removeWorkExperience) props.removeWorkExperience(props.index);
	}
	const getLocationString = () => {
		const locations = [];
		if (locality) locations.push(locality);
		if (city?.name) locations.push(city?.name);
		if (state?.name) locations.push(state?.name);
		if (country?.name) locations.push(country?.name);
		
		return locations.length > 0 ? `, ${locations.join(', ')}` : '';
	  }
	console.log("🚀 ~ WorkExperienceCard ~ props.weItem:", props.weItem)
	let duration;
	if(isOnGoing)
	{
		duration=Utils.getDurationFromDatesUsingMoment(durationFrom,new Date())
	}
	else{
		duration=Utils.getDurationFromDatesUsingMoment(durationFrom,durationTo)
	}
	console.log("🚀 ~ WorkExperienceCard ~ duration:", isOnGoing)
	return (
		<Card className="profile-data-card work-experience-card" >
			{
				!showPreview && (
					<>
						<div className="actions d-flex flex-row align-center justify-content-end">
							<span className="mr-3"><i className="fa fa-pencil-alt" role="button" onClick={handleShow}></i></span>
							<span><i className="fa fa-trash" role="button" onClick={ onDeleteItem }></i></span>
						</div>
						<WorkExperienceEditModal index={props.index} handleClose={handleClose} show={show} weItem={props.weItem} chRef={props.chRef} />
					</>
				)
			}
			
			<Row className="m-0 d-flex align-items-center">
				<i className="fas fa-building fa-5x"></i>
				<h2>
					{designation?.title}
					<label style={{display:"flex",justifyContent:"space-between",marginLeft:"0px"}}>
						{company?.title} { `${getLocationString()}`} 
					<i className='italic'>
						<small>
							{duration}
						</small>
					</i>
					</label>
					<small style={{display:"flex"}}>
						{`${fromMonth} ${duration_from_year} - ${endDate}` }
						<i className='italic'>
						<small className='ml-1'>
							({`${ job_type  ? (job_type == 1 ? 'Full Time' : 'Part Time') : 'N/A' }`})
						</small>
					</i>
						{/* <small>April 2016 - Present (3 years 2 Months) - Full time</small> */}
					</small>
				</h2>
			</Row>
			<p>{description}</p>
		</Card>
	)
}

export default WorkExperienceCard
