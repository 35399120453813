import React, { useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import { EducationSave } from "./EducationSave";
import Autosuggest from "react-autosuggest";
import { EducationContext } from "./EducationContext";
import ProfileDataService from '../../../../services/individual/UserProfile';
import { placeGoogle } from "./googleAddrCall";
import PlacesAutocomplete from "react-places-autocomplete";

/* education modal */
export function Education(props) {
    const [show, setShow] = React.useState(false);

    const handleCloseEdu = () => setShow(false);
    const handleShowEdu = () => setShow(true);
    const [addressLoc, setAddressLoc] = useState("");
    const [instituteName, setInstituteName] = useState("");
    const [courseName, setCourseName] = useState("");
    const [selectedCourseId, setCourseId] = useState(null);
    const [specializationName, setSpecializationName] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [typingTimeout, setTypingTimeout] = useState(null)
    const resetEducationForm = () => {
        setInstituteName("");
        setCourseName("");
        setAddressLoc("");
        setSpecializationName("");
        setCourseId(null);
    }
    function getInstituteList(val) {
        if (!val) return false;
        const input = {
            start: 0,
            length: 100,
            search: val,
            fields: 'id,title,location,instituteId,profilePicture',
            // 'orderBy[column]': 'instituteId',
            // 'orderBy[dir]': 'desc'
        }
        const data = ProfileDataService.fetchInstituteData(input);
        return data;
    }

    function getCourseList(val) {
        if (!val) return false;
        const input = {
            start: 0,
            length: 100,
            search: val,
            fields: 'id,title',
        }
        const data = ProfileDataService.fetchCourseeData(input);
        return data;
    }

    function getSpecializationList(val) {
        if (!val || selectedCourseId == null) return false;
        const input = {
            start: 0,
            length: 100,
            search: val,
            fields: 'id,title,courseId',
            courseId: selectedCourseId,
        }
        const data = ProfileDataService.fetchSpecializationData(input);
        return data;
    }
    // console.log(props.childEduToCall.editEduValues);
    const getEDUmodalData= {...props.childEduToCall.editEduValues,instituteName,courseName,specializationName,addressLoc};

    return (
        <EducationContext.Consumer>
            {({ onEditEduHandler, editEduSuggesions, industries }) => (
                <>
                    <Button
                        onClick={handleShowEdu}
                        variant="white"
                        className="btn float-right"
                        style={{minWidth:"unset !important"}}
                    >
                        <i className="fa fa-plus fa-2x" style={{ color: "#F16823" }}></i>
                    </Button>

                    <Modal className="edit-modals" show={show} onHide={handleCloseEdu} centered size="md" backdrop="static" keyboard={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Education Details</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>
                                    School/College/University <span className="text-danger">*</span>
                                </Form.Label>
                                {/* <Form.Control type="text" name="" id=""></Form.Control> */}
                                <Autosuggest
                                    inputProps={{
                                        placeholder: "Institute Name",
                                        autoComplete: "off",
                                        name: "institute",
                                        id: "institute",
                                        value: instituteName,
                                        onChange: (e, { newValue }) => {
                                            setInstituteName(newValue);
                                            // Add this to handle manual institute entries
                                            editEduSuggesions({
                                                id: 0,
                                                title: newValue
                                            }, props.index, "institute");
                                        }
                                    }}
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={({ value }) => {
                                        if (typingTimeout) {
                                            clearTimeout(typingTimeout);
                                        }
                                        
                                        setTypingTimeout(setTimeout(async () => {
                                            if (value === '') {
                                                setSuggestions([]);
                                                return;
                                            }
                                            try {
                                                const result = await getInstituteList(value)
                                                setSuggestions(
                                                    result.data.data.map((item) => ({
                                                        title: item.title,
                                                        id: item.id
                                                    }))
                                                );
                                            } catch (e) {
                                                console.log(e)
                                                setSuggestions([]);
                                            }
                                        }, 500));
                                    }}

                                    onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                    onSuggetionSelected={(event, { suggestion, method }) => {
                                        if (method === "enter")
                                            event.preventDefault();
                                    }}
                                    getSuggestionValue={(suggestion) => {
                                        editEduSuggesions(suggestion, props.index, "institute");
                                        return suggestion.title
                                    }}
                                    renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                                />
                            </Form.Group>
                                    <Form.Group>
                                        <Form.Label>
                                            Location<span className="text-danger">*</span>
                                        </Form.Label>
                                        <PlacesAutocomplete
                                            autocomplete="off"
                                            value={addressLoc}
                                            onChange={value => {
                                                setAddressLoc(value);
                                                // Store the selected location value
                                                editEduSuggesions(value||"", props.index, "location");
                                            }}
                                            onSelect={value => {
                                                setAddressLoc(value);
                                                editEduSuggesions(value, props.index, "location");
                                            }}
                                        >
                                            {placeGoogle}
                                        </PlacesAutocomplete>
                                    </Form.Group>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label>
                                            Degree<span className="text-danger">*</span>
                                        </Form.Label>
                                        {/* <Form.Control type="text" name="" id=""></Form.Control> */}
                                        <Autosuggest
                                            inputProps={{
                                                placeholder: "Degree Name",
                                                autoComplete: "off",
                                                name: "course",
                                                id: "course",
                                                value: courseName,
                                                onChange: (e, v) => {
                                                    setCourseName(v.newValue)
                                                }
                                            }}
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={({ value }) => {
                                                if (typingTimeout) {
                                                    clearTimeout(typingTimeout);
                                                }
                                                
                                                setTypingTimeout(setTimeout(async () => {
                                                    if (value === '') {
                                                        setSuggestions([]);
                                                        return;
                                                    }
                                                    try {
                                                        const result = await getCourseList(value)
                                                        setSuggestions(
                                                            result.data.data.map((item) => ({
                                                                title: item.title,
                                                                id: item.id
                                                            }))
                                                        );
                                                    } catch (e) {
                                                        console.log(e)
                                                        setSuggestions([]);
                                                    }
                                                }, 500));
                                            }}

                                            onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                            onSuggetionSelected={(event, { suggestion, method }) => {
                                                if (method === "enter")
                                                    event.preventDefault();
                                            }}
                                            getSuggestionValue={(suggestion) => {
                                                setCourseId(suggestion.id)
                                                editEduSuggesions(suggestion, props.index, "course");
                                                return suggestion.title
                                            }}
                                            renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Field of study<span className="text-danger">*</span>
                                        </Form.Label>
                                        {/* <Form.Control type="text" name="" id=""></Form.Control> */}
                                        <Autosuggest
                                            inputProps={{
                                                placeholder: "Specialization",
                                                autoComplete: "off",
                                                name: "specialization",
                                                id: "specialization",
                                                value: specializationName,
                                                onChange: (e, { newValue }) => {
                                                    console.log("new new new ",newValue)
                                                    setSpecializationName(newValue)
                                                    editEduSuggesions({
                                                        id: 0,
                                                        title: newValue
                                                    }, props.index, "specialization");
                                                }
                                            }}
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={({ value }) => {
                                                if (typingTimeout) {
                                                    clearTimeout(typingTimeout);
                                                }
                                                
                                                setTypingTimeout(setTimeout(async () => {
                                                    if (value === '') {
                                                        setSuggestions([]);
                                                        return;
                                                    }
                                                    try {
                                                        const result = await getSpecializationList(value)
                                                        setSuggestions(
                                                            result.data.data.map((item) => ({
                                                                title: item.title,
                                                                id: item.id,
                                                                courseId: item.courseId
                                                            }))
                                                        );
                                                    } catch (e) {
                                                        console.log(e)
                                                        setSuggestions([]);
                                                    }
                                                }, 500));
                                            }}

                                            onSuggestionsClearRequested={() => { setSuggestions([]) }}
                                            onSuggetionSelected={(event, { suggestion, method }) => {
                                                if (method === "enter")
                                                    event.preventDefault();
                                            }}
                                            getSuggestionValue={(suggestion) => {
                                                if (suggestion.id) {
                                                    editEduSuggesions(suggestion, props.index, "specialization");
                                                    return suggestion.title;
                                                }
                                                return specializationName;
                                            }} 
                                            renderSuggestion={suggestion => <div>{suggestion.title}</div>}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Education Type<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            custom
                                            name="type"
                                            id="type"
                                            onChange={event => editEduSuggesions(event.target.value, props.index, event.target.name)}
                                            value={props.childEduToCall.editEduValues.type || 1}
                                        >
                                            <option value="1">Full time</option>
                                            <option value="2">Part time</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Start year<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationFrom"
                                            id="durationFrom"
                                            onChange={event => editEduSuggesions(event.target.value, props.index, event.target.name)}
                                            value={props.childEduToCall.editEduValues.durationFrom || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>End year</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationTo"
                                            id="durationTo"
                                            disabled={props.childEduToCall.editEduValues.isOnGoing ? true : false}
                                            onChange={event => editEduSuggesions(event.target.value, props.index, event.target.name)}
                                            value={props.childEduToCall.editEduValues.durationTo || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        {["checkbox"].map((type) => (
                                            <div key={`custom-${type}`}>
                                                <Form.Check
                                                    custom
                                                    type={type}
                                                    id="isOnGoing"
                                                    name="isOnGoing"
                                                    label="I currently study here"
                                                    onChange={event => editEduSuggesions(event.target.checked, props.index, event.target.name)}
                                                    checked={props.childEduToCall.editEduValues.isOnGoing}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="text-center pt-3">
                                <EducationSave resetEducationForm={resetEducationForm} clickThis={props.chRef.addEducation} closeMain={handleCloseEdu} EDUmodalData={getEDUmodalData} />
                            </div>

                        </Modal.Body>
                    </Modal>
                </>
            )}
        </EducationContext.Consumer>
    );
}
