/* ----------  Imports  ---------- */

// Lodash
import { truncate } from "lodash";
import moment from "moment";

/* ----------  Utils  ---------- */

const Utils = {
	getUserType: userType => {
		switch (parseInt(userType)) {
			case 1: return 'user';
			case 2: return 'company';
			case 3: return 'institute';
			default: return null;
		}
	},

	getUserProfilePhotoUrl: (profilePhoto, userType = 1) => {
		const type = Utils.getUserType(userType);
		if(!profilePhoto) return `/assets/images/default-avatars/default-${ type }.png`;
		return `${ process.env.REACT_APP_API_PUBLIC_URL }/${ type }/images/${ profilePhoto }`;
	},

	truncateText: (text, length = 100, omission = '...') => truncate(text, { length, omission }),

	titleCase: (str) => {
		str = str.toLowerCase().split(' ');
		for (var i = 0; i < str.length; i++) {
		  str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
		}
		return str.join(' ');
	},
	formatNumber:(number) =>{
		let num = parseFloat(number);
		if (num % 1 === 0) {
			return num.toString();
		} else {
			return num.toFixed(2).replace(/\.00$/, '').replace(/\.0$/, '');
		}
	},
	getDurationFromDatesUsingMoment : (startDate, endDate) => {
		try {
			if (!startDate || !endDate) return null;
			const start = moment(startDate);
			const end = moment(endDate)
			if (!start.isValid() || !end.isValid()) return null;
			const years = end.diff(start, 'years');
			start.add(years, 'years');
			const months = end.diff(start, 'months');
			start.add(months, 'months');
			let durationString = [];
			if (years > 0) durationString.push(`${years} year${years > 1 ? 's' : ''}`);
			if (months > 0) durationString.push(`${months} month${months > 1 ? 's' : ''}`);
			return durationString.join(' ');
		} catch (error) {
			console.error(error);
		}
	}
}

/* ----------  Export  ---------- */
export default Utils;