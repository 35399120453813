import React, { useState } from "react";
import { Row, Card, Collapse } from "react-bootstrap";
import { EditCertificationModal } from "./EditCertificationModal";

/**
 * [CerificationCollapse description]
 * @param {[type]} props [description]
 */
export function CertificationCollapse(props) {
    const showPreview = props.showPreview === undefined ? true : props.showPreview;
    const [open, setOpen] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onDeleteItem = (e, id) => {
        e.preventDefault();
        if (props.removeCerification) props.removeCerification(id);
    }

    return (
        <>
            <div id="CerificationCollapse mt-2">
                {props.certification.length > 0 ?
                    props.certification.map((item, index) => {
                        let fromDate = new Date(`${item.duration_from_year}-${item.duration_from_month}`);
                        let fromMonth = fromDate.toLocaleString('default', { month: 'long' })
                        let toDate = new Date(`${item.duration_to_year}-${item.duration_to_month}`);
                        let toMonth = toDate.toLocaleString('default', { month: 'long' });

                        const certificationCompleteDate = `${toMonth} ${item.duration_to_year}`;

                        return (<Card className="profile-data-card" key={item.id}>
                            {showPreview === false ?
                                <>
                                    <div className="actions d-flex flex-row align-center justify-content-end">
                                        <span className="mr-3"><i className="fa fa-pencil-alt" role="button" onClick={handleShow}></i></span>
                                        <span><i className="fa fa-trash" role="button" onClick={e => onDeleteItem(e, item.id)}></i></span>
                                    </div>
                                    <EditCertificationModal key={item.id} index={index} handleClose={handleClose} show={show} accomItem={item} />
                                </>
                                :
                                ""
                            }
                            <Row className="m-0">
                                <i className="fa fa-file card-icon"></i>
                                <h2>
                                    {item.title}
                                    <small>{`${fromMonth} ${item.duration_from_year} - ${item.isOnGoing ? 'Present' : certificationCompleteDate}`}</small>
                                </h2>
                            </Row>
                            <p>{item.description}</p>
                        </Card>)
                    })
                    :
                    <>
                        {/* <h3>Certifications</h3> */}
                        {/* <div className="no-data">
                            Add your certifications to improve your profile
                            </div> */}
                    </>
                }
            </div>
        </>
    )
}
