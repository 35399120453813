import React, { useState } from 'react';
import {Row, Col, Button, Card, Modal } from 'react-bootstrap';
import { map } from 'lodash';

/* tracking modal */
function TrackModal(props) {
	const { data, reverse } = props;
	const [show, setShow] = useState(false);

	const handleCloseApply = () => setShow(false);
	const handleShowApply = () => setShow(true);

	const renderDivider = () => (
		<div className="item arrow">
			{/* <i class="fas fa-arrow-right"></i> */}
			<img src="/assets/long-arrow-right.svg" alt="" />
		</div>
	)

	if (!data) return '';
	const renderUsers = () => {
		let chain = [...data]
		if (reverse == true) {
			chain.reverse()
		}
		return map(chain, (item, i) => {
			const avatar = `${process.env.REACT_APP_API_PUBLIC_URL}/user/images/${item.user.profilePicture}`;
			const hasDivider = (i > 0) && (i < chain.length);

		let date = new Date(item?.acceptedRejectedAt);
		let dateOptions = {
		day: '2-digit',
		month: 'long',
		year: 'numeric'
		};
		let timeOptions = {
			hour: '2-digit',
			minute: '2-digit',
			hour12: true
		};
		let formattedDate = date.toLocaleDateString('en-GB', dateOptions);
		let formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
			return (
				<React.Fragment key={`trackUser_${item.id}`}>
					{ hasDivider ? renderDivider() : ''}
					<div className="item">
						{item.status == 1 ? <i className="fa fa-check"></i> :<i class="far fa-clock"></i>}
						<img alt="pic" src={avatar} />
						<label className='pb-2'>{item.user.firstName} {item.user.lastName}</label>
						<p className=" text-muted noMargins" style={{ fontSize: '10px' }}>{formattedDate}</p> 
						<p className=" text-muted noMargins" style={{ fontSize: '10px'  }}>{formattedTime}</p> 
						{/* <p className="font-italic text-muted" style={{ fontSize: '10px' }}>20 Jan 2020,<br />
						10:20 AM</p> */}
						
					</div>
				</React.Fragment>
			);
		});
	}

	return (
		<>
			<Button onClick={handleShowApply} className={`btn btn-rounded btn-track ${props.btnClass || ''}`} variant="">
				<div className="no">
					{props.degree || data.length}
					<svg width={`${props.iconWidth??"30"}`} height={`${props.iconHeight??"30"}`} viewBox="0 0 30 30">
						<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
					</svg>
				</div>
			</Button>

			<Modal show={show} onHide={handleCloseApply} centered size='xl' dialogClassName="modal-width-fit" className="apply-modal customPaddings" >
				<Modal.Header closeButton className='noBorders mx-6 tracker-header'>
					<Modal.Title>{props.modalTitle}</Modal.Title>
                    {props.showBonus ?
                        <Col className="text-right"><i className="fa fa-money-bill-alt mr-1 mt-1 text-orange"></i>₹ {props.bonusAmount}/-</Col>
                        : ''
                    }
				</Modal.Header>
				<Modal.Body className='noBlockPaddings'>
					{/* <Row>
						<Col>
							<p>
								{props.modalSubTitle || 'Track:'}
							</p>
						</Col>
					</Row> */}
					<Card className="p-0 border-0">
						<div className="refered-list" style={{display: 'flex',justifyContent:'center',paddingBlock:"16px",paddingInline:"0px",paddingRight:`${data?.length>1?"20px":"0px"}`}}>
							{renderUsers()}

						</div>
					</Card>
				</Modal.Body>
				<Modal.Footer className="justify-content-center noPaddings">
					<Button onClick={handleCloseApply} variant="dark">OK</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default TrackModal;