import React, { useState, useEffect } from 'react';
import { Container, Row, Col, InputGroup, Form, Button, Dropdown, Card, Modal, Collapse, Spinner } from 'react-bootstrap';

import WallDataService from "../../../../../services/individual/IndividualWall";
import ReferralService from "../../../../../services/individual/referral";
import { findIndex, isEmpty, join, map } from 'lodash';
import { Link } from 'react-router-dom';
import RequirementComponent from '../../exploreComponent/requirementsComponent/requirement';
import { ConnectionsBlock } from '../../connectionComponent/connection';
import Utils from '../../../../../Helpers/utils';
import IndividualPostCard, { ApplyModal, SuccessModal, PostReferouteModal } from '../../../../Common/Components/IndividualPostCard';
import { useModal } from '../../../../../context/ModalContext';

function IndividualWallComponent(props) {
	console.log({props});
	const { openModal } = useModal();

	const handleSave = () => {
	  openModal({
		title: 'Your accomplishment added successfully',
		buttonText: 'Okay',
	  });
	};
	const [wallPosts, setWallPosts] = useState([]);
	const [busy, setBusy] = useState(true);
	const [showApplyModal, setShowApplyModal] = useState(false);
	const [showReferModal, setShowReferModal] = useState(false);
	const [activePost, setActivePost] = useState({});

	const [successModalText, setSuccessModalText] = useState('');
	const [showSuccessModal, setShowSuccessModal] = useState(false);
	const [searchbarKeyword, setSearchbarKeyword] = useState('');

	const settings = {
		isLoading: false,
		total: 0,
		loaded: 0,
		post_type: "",
		search: '',
		creatorType: "",
		creatorId: "",
		cityId: "",
		bonusType: "",
		experienceFrom: "",
		experienceTo: "",
		salaryFrom: "",
		salaryTo: "",
		"orderBy[column]": "activatedOn",
		"orderBy[dir]": "desc",
		industryId: '',
		scopeUserMinBonus: true,
	};

	let params = Object.assign({
		start: settings.loaded,
		length: 50,
		bonusDistribution: true,
		referAndApplyCount: true,
	}, settings);


	const getPosts = async (params) => {
		try {
			setBusy(true);
			console.log({params});
			const { data } = await WallDataService.fetchIndividualPostsForWall(params);
			setWallPosts(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	useEffect(() => {
		console.log('new search');
		// if(props.searchKeyword && props.searchKeyword.length > 0){
			setSearchbarKeyword(props.searchKeyword)
			const params = {
				isLoading: false,
				total: 0,
				loaded: 0,
				post_type: "",
				search: props.searchKeyword,
				creatorType: "",
				creatorId: "",
				cityId: "",
				bonusType: "",
				experienceFrom: "",
				experienceTo: "",
				salaryFrom: "",
				salaryTo: "",
				"orderBy[column]": "activatedOn",
				"orderBy[dir]": "desc",
				industryId: '',
				scopeUserMinBonus: true,
				start: 0,
				length: 50,
				bonusDistribution: true,
				referAndApplyCount: true,
			};

			getPosts(params);
		// }
	  }, [props.searchKeyword]);

	const handleApplyModalShow = (show, postId) => {
		setShowApplyModal(show);
		const post = show ? wallPosts.find(p => p.id == postId) : {};
		setActivePost(post);
	}

	const handleReferModalShow = (show, postId) => {
		setShowReferModal(show);
		const post = show ? wallPosts.find(p => p.id == postId) : {};
		setActivePost(post);
	}

	useEffect(() => {
		getPosts(params);
	}, []);

	const renderLatestPosts = () => {
		if (busy) {
			return (
				<Col className="text-center">
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
				</Col>
			);
		}

		if (!busy && isEmpty(wallPosts)) return <Col><p className="text-muted text-center">No posts found!</p></Col>;

		return <LatestPosts data={wallPosts} setApplyModalShow={handleApplyModalShow} setReferModalShow={handleReferModalShow} />
	}

	return (
		<React.Fragment>
			{/* <WallFilters /> */}
			<div className="wall">
				<Container className="pt-3">
					{/* <div className="filterTags pt-0 pb-3">
						<span className="filter-badge">₹ 1500 min bonus <i className="fa fa-times" style={{ color: "orange" }}></i></span>
					</div> */}
					<Row>
						{renderLatestPosts()}
					</Row>
				</Container>
				<div className="recoPost bg-light py-3 mt-5">
					<Container>
						<h1>Explore posts</h1>
						<RequirementComponent maxPosts={3} filters={false} />
						<div className="text-right"><a className="btn btn-orange-gray" href="/individual/explore">View All</a></div>
					</Container>
				</div>
				<div className="py-3">
					<Container>
						<h1>Recommended connections</h1>
						<Row>
							<ConnectionsBlock tab="recommended" selectedTab="recommended" />
						</Row>
						<div className="text-right"><a className="btn btn-orange-gray" href="/individual/connection">View All</a></div>
					</Container>
				</div>
			</div>
			<PostReferouteModal post={activePost} postId={activePost.id} show={showReferModal} setSuccessShow={setShowSuccessModal} setShow={handleReferModalShow} setSuccessText={setSuccessModalText} />
			<ApplyModal post={activePost} postId={activePost.id} show={showApplyModal} setSuccessShow={setShowSuccessModal} setShow={handleApplyModalShow} setSuccessText={setSuccessModalText} />
			<SuccessModal text={successModalText} show={showSuccessModal} setShow={setShowSuccessModal} setText={setSuccessModalText} />
		</React.Fragment>
	);
}

export default IndividualWallComponent;


function LatestPosts(props) {
	const { data } = props;

	const onApplyClick = (e, item) => {
		e.preventDefault();
		if (props.setApplyModalShow) props.setApplyModalShow(true, item.id);
	}

	const onReferClick = (e, item) => {
		e.preventDefault();
		if (props.setReferModalShow) props.setReferModalShow(true, item.id);
	}

	return map(data, item => {
		const expFrom = item.experienceFrom || 0;
		const expTo = item.experienceTo || 0;
		const exp = expTo >= 0 ? `${parseFloat(expFrom.toString())} - ${parseFloat(expTo.toString())} yrs` : 'N/A';

		const salaryFrom = item.salaryFrom || 0;
		const salaryTo = item.salaryTo || 0;
		const salary = salaryTo >= 0 ? `${parseFloat(salaryFrom.toString())} - ${parseFloat(salaryTo.toString())} LPA` : 'N/A';

		const avatar = Utils.getUserProfilePhotoUrl(item.creatorDetails.profilePicture, item.creatorType);
		const bonuDist = item.bonusDistribution || []
		// let bonusAmount = 0
		// if (typeof item.positionInReferralChain !== typeof undefined && bonuDist.length > item.positionInReferralChain) {
		// 	bonusAmount = bonuDist[item.positionInReferralChain]
		// }

		return <IndividualPostCard guard="individual" view="wall" type="post" post={ item } key={ item.id }/>

		/* return (
			<Col md={6} xl={4} key={item.id}>
				<Card className={`card-post indCard ${item.bonus_amount ? 'postCard bonusPost' : 'postCard'}`}>
					{item.bonus_type != null  && item.minBonus !=0 ? (
						<div className="strip d-flex align-items-center">
							<i className="fa fa-ticket-alt fa-2x mr-2"></i>₹ {item.minBonus}/- min &nbsp;&nbsp;|&nbsp;&nbsp; {item.expiredInDays} Days
						</div>
					) : <div className="strip d-flex align-items-center py-3"></div>}
					<Row className="m-0 align-items-center mt-3">
						<img alt="pic" src={avatar} className="companyPic" />
						<h2>
							{item?.creatorDetails?.name}
							<small>{item.companyIndustry}</small>
						</h2>
					</Row>
					<Link to={`/individual/post/details/${item.id}`} target="_blank">
						<h2 className="mt-3">
							<div className="font-weight-bold text-nowrap">{Utils.truncateText(item.title)}</div>
							<div className="mt-2">{item.designation}</div>
						</h2>
						<p>{Utils.truncateText(item.description)}</p>
					</Link>
					<Row>
						<Col>
							<div className="fInfo  text-nowrap">
								<i className="fa fa-briefcase fa-lg"></i>{exp}
							</div>
						</Col>
						<Col>
							<div className="fInfo text-nowrap">
								<i className="fa fa-money-bill-alt fa-lg"></i>{salary}
							</div>
						</Col>

					</Row>
					<Row>
						<Col>
							<div className="fInfo py-3">
								<i className="far fa-clock mr-1 fa-lg"></i>Full time
							</div>
						</Col>
						<Col>
							<div className="fInfo py-3">
								<i className="fa fa-map-marker-alt fa-lg"></i>{item.city}
							</div>

						</Col>
					</Row>
					<Row>
						{
							item.lastViewedAt ? <Col md={'auto'}>
								<span><i className="fa fa-eye mr-1"></i><span className="text-orange font-italic">Viewed</span></span>
							</Col> : ''
						}
						<Col className="text-right"><span><i className="fa fa-clock mr-1"></i>Posted {item.created_at_date}</span></Col>
					</Row>
					<Row className="card-footer-actions institute-posts__footer">
						{ item.showDirectApplyButton ? <Col className="p-0"><Button variant="white">APPLY</Button></Col> : '' }
						<Col className="p-0">
							<Button variant="white" onClick={e => onApplyClick(e, item)}>
								APPLY {!item.showDirectApplyButton ? <>({item.applyCount || '0'})</> : <></>}
							</Button>
						</Col>
						<Col className="p-0"><Button variant="white" onClick={e => onReferClick(e, item)}>REFEROUTE ({item.referCount || '0'})</Button></Col>
						{item.post_type === 3 ? <Col className="p-0"><Button as={Link} to={`/individual/post/details/${item.id}`} variant="white">COMMENTS ({item.totalComments || '0'})</Button></Col> : ''}
					</Row>
				</Card>
			</Col>
		) */
	})
}

/* apply modal */
/* function ApplyModal(props) {
	const [busy, setBusy] = useState(true);
	const [applying, setApplying] = useState(false);

	const [data, setData] = useState([]);
	const [refers, setRefers] = useState([]);

	const onSubmit = e => {
		e.preventDefault();
		submitForm();
	}

	const getReferralChain = async () => {
		if (!props.show || !props.postId) return;

		const params = {
			postId: props.postId,
			start: 0,
			length: 50,
			search: '',
		}

		try {
			setBusy(true);
			const { data } = await ReferralService.GetPostReferrals(params);
			setData(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const changeRefers = item => {
		const iRefers = [...refers];
		const referIndex = findIndex(iRefers, { userId: item.userId });

		if (referIndex >= 0) {
			iRefers.splice(referIndex, 1);
			setRefers(iRefers);
			return;
		}

		const refer = {
			userId: item.userId,
			postChainId: item.postChainId
		}

		iRefers.push(refer);
		setRefers(iRefers);
	}

	const hideModal = () => {
		if (props.setShow) props.setShow(false);
	}

	const submitForm = async () => {
		if (!refers.length) return;

		const formData = {
			postId: props.postId,
			refers,
		}

		try {
			setApplying(true);
			const { data } = await ReferralService.SendPostReferrals(formData);
			if (!data.success) return;
			setData([]);
			setRefers([]);
			hideModal();
			if (props.setSuccessShow) props.setSuccessShow(true);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getReferralChain();
	}, [props.show]);

	const renderItems = () => {
		if (busy) return <p className="text-muted text-center m-0 p-2">Loading...</p>;
		if (!busy && !data.length) return <p className="text-muted text-center m-0 p-2">No referrals found.</p>;

		return map(data, item => {
			const itemData = {
				postId: item.postId,
				postChainId: item.postChainId,
				maxDegree: item.maxDegree,
				postChainStatus: item.postChainStatus,
				userId: item.applyingThroughUserId,
				name: item.applyingThroughUserName,
				profilePicture: item.applyingThroughUserProfilePicture,
			}

			return <ChainCollapse changeRefers={changeRefers} data={itemData} key={item.applyingThroughUserId} />;
		});
	}

	return (
		<Modal show={props.show} onHide={hideModal} centered backdrop="static" scrollable={true} size="lg" className="apply-modal">
			<form onSubmit={onSubmit}>
				<Modal.Header closeButton>
					<Modal.Title>APPLY<i className="text-orange ml-2 font-weight-normal">via any 1 network per post</i></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p className="sub-p my-2 py-2">
						Your matching rate to this requirement is <span className="badge badge-green">60%</span>
					</p>
					<p className="font-italic">Below are suggested connections through whom you can process a referral for your application through any 1 connection (or chain) per 24 hours.</p>
					<Row className="mt-3 mb-2">
						<Col md={'auto'} className="border-right pr-md-4">
							<h1 className="m-0">Apply via ({data.length})</h1>
						</Col>
						<Col md={4} lg={4} className="pl-md-4">
							<InputGroup className="search-input">
								<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Col>
					</Row>
					<Card className="scroll-y">
						{renderItems()}
					</Card>
					<p className="text-orange mt-3 mb-0">
						Selected <span className="text-primary">John Doe</span> To Process Your Application
					</p>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" type="submit" disabled={!refers.length || applying}>{applying ? 'Applying...' : 'APPLY NOW'}</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

// Post Referoute Modal

const PostReferouteModal = props => {
	const [busy, setBusy] = useState(true);
	const [applying, setApplying] = useState(false);

	const [data, setData] = useState([]);
	const [refers, setRefers] = useState([]);

	const onSubmit = e => {
		e.preventDefault();
		submitForm();
	}

	const getPostRefers = async () => {
		if (!props.show || !props.postId) return;

		const params = {
			postId: props.postId,
			start: 0,
			length: 50,
			search: '',
			isRecommended: 1
		}

		try {
			setBusy(true);
			const { data } = await ReferralService.GetPostRefers(params);
			setData(data.data);
		} catch (error) {
			console.log(error);
		} finally {
			setBusy(false);
		}
	}

	const changeRefers = item => {
		const iRefers = [...refers];
		const referIndex = findIndex(iRefers, { userId: item.userId });

		if (referIndex >= 0) {
			iRefers.splice(referIndex, 1);
			setRefers(iRefers);
			return;
		}

		const refer = {
			userId: item.userId,
			chainId: item.postChainId
		}

		iRefers.push(refer);
		setRefers(iRefers);
	}

	const hideModal = () => {
		if (props.setShow) props.setShow(false);
	}

	const submitForm = async () => {
		if (!refers.length) return;

		const formData = {
			postId: props.postId,
			refers,
		}

		try {
			setApplying(true);
			const { data } = await ReferralService.SendPostRefers(formData);
			if (!data.success) return;
			setData([]);
			setRefers([]);
			hideModal();
			if (props.setSuccessText) props.setSuccessText('Users referred successfully!');
			if (props.setSuccessShow) props.setSuccessShow(true);
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		getPostRefers();
	}, [props.show]);

	const renderItems = () => {
		if (busy) return <p className="text-muted text-center m-0 p-2">Loading...</p>;
		if (!busy && !data.length) return <p className="text-muted text-center m-0 p-2">No referrals found.</p>;

		return map(data, item => {
			const itemData = {
				postId: item.postId,
				postChainId: item.postChainId,
				maxDegree: item.referForDegree,
				postChainStatus: item.postChainStatus,
				userId: item.referringToUserId,
				name: item.referringToUserName,
				profilePicture: item.referringToProfilePicture,
			}

			return <ChainCollapse changeRefers={changeRefers} data={itemData} key={item.referringToUserId} />;
		});
	}

	return (
		<Modal show={props.show} onHide={hideModal} backdrop="static" centered size="lg" className="apply-modal" scrollable={true}>
			<form onSubmit={onSubmit}>
				<Modal.Header className="align-items-center" closeButton>
					<Modal.Title>REFEROUTE</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-0 scroll-y">
					<p>The referoute process will be completed once such referral is approved by the entire chain and subject to the selection of the referred profile. Your earning will be as per the bonus disbursement timeline defined in the post. Know more</p>
					<h1>Your bonus earning at each degree is as below:</h1>
					<div className="deg d-md-flex mt-2">
						<div className="item col-md">
							<div className="no">1
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="30" strokeDashoffset="1" />1
								</svg>
							</div>
							<label>₹ 3000</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">2
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="20" strokeDashoffset="2" />
								</svg>
							</div>
							<label>₹ 2500</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">3
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="15" strokeDashoffset="3" />
								</svg>
							</div>
							<label>₹ 2000</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">4
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="10" strokeDashoffset="4" />
								</svg>
							</div>
							<label>₹ 1500</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">5
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="8" strokeDashoffset="4" />
								</svg>
							</div>
							<label>₹ 1000</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">6
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="7" strokeDashoffset="4" />
								</svg>
							</div>
							<label>₹ 800</label>
						</div>
						<div className="item col-md">
							<div className="dash"></div>
						</div>
						<div className="item col-md">
							<div className="no">7
								<svg width="30" height="30" viewBox="0 0 30 30">
									<circle strokeLinecap="round" cx="15" cy="15" r="13" stroke="#EB5015" strokeWidth="1" fill="none" strokeDasharray="6" strokeDashoffset="4" />
								</svg>
							</div>
							<label>₹ 500</label>
						</div>
					</div>
					<Row className="align-items-center my-3">
						<Col md={'4'}>
							<h1 className="m-0">Suggested matches ({data.length})</h1>
						</Col>
						<Col md={'6'}>
							<InputGroup className="search-input">
								<Form.Control type="text" name="" id="" placeholder="Search person, institute"></Form.Control>
								<InputGroup.Append><Button variant="white" className="bordered btn-auto"><i className="fa fa-search"></i></Button>
								</InputGroup.Append>
							</InputGroup>
						</Col>
					</Row>
					<Card>
						{renderItems()}
					</Card>
					<h1 className="mt-2 font-italic text-muted"><small>0 profile and 0 chains selected</small></h1>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button variant="dark" type="submit" disabled={!refers.length || applying}>{applying ? 'PLease Wait...' : 'Referoute'}</Button>
				</Modal.Footer>
			</form>
		</Modal>
	);
}

function SuccessModal(props) {
	const { show, text } = props;

	const onHide = () => {
		if (props.setShow) props.setShow(false);
		if (props.setText) props.setText('');
	}

	return (
		<Modal show={show} onHide={onHide} centered size="md" className="save-modal">
			<Modal.Header closeButton></Modal.Header>
			<Modal.Body>
				<i className="fa fa-check"></i>
				<h2>{text || 'You have applied for the post successfully'}</h2>
				<div className="text-right">
					<Button onClick={onHide} variant="dark">Ok</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
} */
