// ProjectsCollapse.js
import React, { useState } from "react";
import { Row, Card, Collapse } from "react-bootstrap";
import { EditProjectModal } from "./EditProjectModal";

export function ProjectsCollapse(props) {
  const showPreview = props.showPreview === undefined ? true : props.showPreview;
  const [open, setOpen] = useState(true);
  const [showModal, setShowModal] = useState(-1); // -1 means no modal is open

  const handleCloseModal = () => setShowModal(-1);
  const handleShowModal = (index) => setShowModal(index);

  const onDeleteItem = (e, index) => {
    e.preventDefault();
    if (props.removeProject) props.removeProject(index);
  };

  return (
    <>
      <div id="ProjectCollapse mt-2">
        {props.projects.length > 0 ?
          props.projects.map((item, index) => {
            let fromDate = new Date(`${item.duration_from_year}-${item.duration_from_month}`);
            let fromMonth = fromDate.toLocaleString('default', { month: 'long' })
            let toDate = new Date(`${item.duration_to_year}-${item.duration_to_month}`);
            let toMonth = toDate.toLocaleString('default', { month: 'long' });

            const projectCompleteDate = `${toMonth} ${item.duration_to_year}`;

            return (
              <Card className="profile-data-card accomplishment-card" key={index}>
                {showPreview === false ?
                  <>
                    <div className="actions d-flex flex-row align-center justify-content-end">
                      <span className="mr-3"><i className="fa fa-pencil-alt" role="button" onClick={() => handleShowModal(index)}></i></span>
                      <span><i className="fa fa-trash" role="button" onClick={(e) => onDeleteItem(e, index)}></i></span>
                    </div>
                    <EditProjectModal
                      key={index}
                      index={index}
                      handleClose={handleCloseModal}
                      show={showModal === index}
                      accomItem={item}
                    />
                  </>
                  :
                  ""
                }
                <Row className="m-0">
                  <i className="fa fa-file card-icon fa-5x"></i>
                  <h2>
                    {item.title}
                    <small>{`${fromMonth} ${item.duration_from_year} - ${item.isOnGoing ? 'Present' : projectCompleteDate}`}</small>
                  </h2>
                </Row>
                <p>{item.description}</p>
              </Card>
            )
          })
          :
          <>
           {/* <div className="no-data">
              Add your Projects to improve your profile
            </div> */}
          </>
        }
      </div>
    </>
  )
}