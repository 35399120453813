/* ----------  Imports  ---------- */
// React
import React, { useState } from "react";

// Bootstrap
import {Form, Button, Modal } from "react-bootstrap";

// Services
import ProfileDataService from '../../../../services/individual/UserProfile';
import { useModal } from "../../../../context/ModalContext";

/* ----------  Component  ---------- */

export default function PhoneVerificationModal({ value, countryCode, ...props }) {
	console.log('🚀 ~ PhoneVerificationModal ~ props:',value,countryCode);
	const [phoneVerification, setPhoneVerification] = useState({
		otp: '',
		ref_id: ''
	});
	const {openModal} =useModal()
	const [isSentOtp, setIsSentOtp] = useState(false);
	const [timer, setTimer] = useState(0);
	const [canResend, setCanResend] = useState(false);
	
	const startTimer = () => {
	  setTimer(60);
	  setCanResend(false);
	  const interval = setInterval(() => {
		setTimer((prevTimer) => {
		  if (prevTimer <= 1) {
			clearInterval(interval);
			setCanResend(true);
			return 0;
		  }
		  return prevTimer - 1;
		});
	  }, 1000);
	};
	
	const handleSendOtp = () => {
	  ProfileDataService.sendPhoneVerificationOtp(countryCode,value)
		.then((res) => {
		  if (res.status === 200) {
			setPhoneVerification({...phoneVerification, ref_id:res.data.data.ref_id})
			setIsSentOtp(true);
			startTimer(); // Start timer after successful OTP send
		  }
		})
		.catch(error => {
		  console.log(error);
		});
	}
	
	// Add this in the Modal.Body where the OTP input is shown:
	
	// const handleSendOtp = () => {
	// 	ProfileDataService.sendPhoneVerificationOtp(countryCode,value).then((res)=>{
	// 		if(res.status === 200){
	// 			setPhoneVerification({...phoneVerification,ref_id:res.data.data.ref_id})
	// 			setIsSentOtp(true);
	// 		}
	// 	}).catch(error => {
	// 		console.log(error);
	// 	}); 
	// }

	const handleOtpVerification = () =>{
		console.log("🚀 ~ handleOtpVerification ~ phoneVerification:", phoneVerification)
		ProfileDataService.verifyPhoneOtp(phoneVerification).then((res)=>{
			console.log(res);
			if(res.data.success){
				setIsSentOtp(false);
				props.hideVerificationModal();
				openModal({
					title: `Mobile Number Verified Successfully`,
					type: 'success',
				});
			}
		}).catch(error => {
			openModal({
				title: `Mobile Number Verification Failed. Kindly Retry`,
				type: 'error',
				icon:"fa-times",
				setTimeout:true
			});
			console.log(error);
		});
	}
	return (
		<Modal className="edit-modals" show={props.isShowModal} onHide={props.hideVerificationModal} centered size="md" backdrop="static" keyboard={false}>
			<Modal.Header closeButton>
				<Modal.Title>Mobile No. Verification</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			{isSentOtp && (
			<>
				<Form.Group>
				<Form.Control
					type="text"
					name="phoneOtp"
					id="phoneOtp"
					onChange={e => setPhoneVerification({...phoneVerification,otp:e.target.value})}
				></Form.Control>
				<p>We have sent you OTP on {props.fullMobileNo}</p>
				
				</Form.Group>
				<div className="text-center pt-3">
				<Button className="btn btn-dark mr-2 px-5" onClick={handleOtpVerification}>
					Submit
				</Button>
				<div className="resendOtp">
					<a 
						className="text-underline"
						onClick={handleSendOtp}
						disabled={!canResend}
						>
						Resend OTP {timer > 0 && `(${timer}s)`}
					</a>
				</div>
				</div>
			</>
			)}
				{!isSentOtp && (
					<div className="text-center ">
						<p>Press send button to verify your Mobile no. with us.</p>
						<Button className="btn btn-dark mr-2 px-5" onClick={handleSendOtp}>
							Send
						</Button>
					</div>
				)}

			</Modal.Body>
		</Modal>
	);
}
