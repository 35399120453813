import React, { useState } from "react";
import { Row, Col, Form, Modal } from "react-bootstrap";
import { AccomplishmentSave } from "./AccomplishmentSave";
import { AccomplishmentContext } from "./AccomplishmentContext";
/* accomplishment modal */
export function EditProjectModal(props) {
    const { type, title, isOnGoing, durationFrom, durationTo, description, projectDoneAt } = props.accomItem;
    // const [show, setShow] = useState(false);
    // const handleCloseAcc = () => setShow(false);
    // const handleShowAcc = () => setShow(true);
    // const [accType, setAccType] = useState(type.title);

    return (
        <AccomplishmentContext.Consumer>
            {({ onChangeAccomplishment }) => (
                <>
                    <Modal className="edit-modals" show={props.show} onHide={props.handleClose} centered size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Project</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group>
                                <Form.Label>
                                    Project Title<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    id="title"
                                    onChange={event => onChangeAccomplishment(event, props.index, "projects")}
                                    value={title || ""}
                                ></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Project done at<span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    name="projectDoneAt"
                                    id="projectDoneAt"
                                    onChange={event => onChangeAccomplishment(event, props.index, "projects")}
                                    value={projectDoneAt || ""}
                                ></Form.Control>
                            </Form.Group>

                            <Row>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>
                                            Start year<span className="text-danger">*</span>
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationFrom"
                                            id="durationFrom"
                                            onChange={event => onChangeAccomplishment(event, props.index, "projects")}
                                            value={durationFrom || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>End year</Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="durationTo"
                                            id="durationTo"
                                            disabled={isOnGoing ? true : false}
                                            onChange={event => onChangeAccomplishment(event, props.index, "projects")}
                                            value={durationTo || ""}
                                        ></Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        {["checkbox"].map((type) => (
                                            <div key={`custom-${type}`}>
                                                <Form.Check
                                                    custom
                                                    type={type}
                                                    id="isOnGoing"
                                                    name="isOnGoing"
                                                    label="No expiry"
                                                    onChange={event => onChangeAccomplishment(event, props.index, "projects")}
                                                    checked={isOnGoing || false}
                                                />
                                            </div>
                                        ))}
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    row="4"
                                    type="text"
                                    name="description"
                                    id="description"
                                    onChange={event => onChangeAccomplishment(event, props.index, "projects")}
                                    value={description || ""}
                                ></Form.Control>
                            </Form.Group>
                            <div className="text-center pt-3">
                                <AccomplishmentSave buttonText="Update" closeModal={props.handleClose} />
                            </div>

                        </Modal.Body>
                    </Modal>
                </>
            )}
        </AccomplishmentContext.Consumer>
    );
}
