import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useModal } from '../../../../context/ModalContext';
/* accomplishment success modal */
export function AccomplishmentSave(props) {
  const { openModal } = useModal();

	const handleShowAccSave = () => {
		if (props.clickThis) {
			props.clickThis();
		}
		props.closeModal();
		openModal({
			title: `Your accomplishment ${
				props.buttonText === 'Update' ? 'updated' : 'added'
			} successfully!`,
			type: 'success',
		});
	};

	return (
		<>
			<Button onClick={handleShowAccSave} variant="dark" type="submit">
				{props.buttonText ?? 'Save'}
			</Button>

			{/* <Modal
        show={show}
        onHide={handleCloseAccSave}
        centered
        size="md"
        className="save-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <i className="fa fa-check"></i>
          <h2>Your accomplishment added successfully</h2>
          <div className="text-center">
            <Button onClick={handleCloseAccSave} variant="dark">
              Okay
            </Button>
          </div>
        </Modal.Body>
      </Modal> */}
		</>
	);
}
