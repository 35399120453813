import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { AuthProvider } from './context/auth'

import Individual from './Modules/Individual/Components/Individual';
import Corporate from './Modules/Corporate/Components/Corporate';
import Institute from './Modules/Institute/Components/Institute';
import NotFound from './Modules/Common/Components/NotFound';
import Layout from './Modules/Common/Components/Layout';
import LoginComponent from './Modules/auth/loginComponent/login';
import SignIn from './Modules/auth/loginComponent/SignIn';
import Admin from './Modules/adminComponent/admin';
import EmailVerification from './Modules/auth/loginComponent/emailVerification';
import { ModalProvider } from './context/ModalContext';
import GlobalModal from './Modules/Common/Components/globalModal/globalModal';

/*const authLink = setContext(() => {
  const token = localStorage.getItem('refToken');
  console.log('Token ', token)
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
})*/

function App() {
  return (
	<React.Fragment>
			<Layout>
				{/* <NavBar /> */}
				<AuthProvider>
				<ModalProvider>
					<Router>
						<Switch>
							<Route exact path="/" component={ LoginComponent } />
							{/* <Route path="/login" component={ SignIn } /> */}
							<Route path="/individual" component={ Individual } />
							<Route path="/corporate" component={ Corporate } />
							<Route path="/institute" component={ Institute } />
							<Route path="/admin" component={ Admin } />
							<Route path="/verify/email/" component={ EmailVerification } />
							<Route component={ NotFound } />
						</Switch>
						<GlobalModal />
					</Router>
					</ModalProvider>
				</AuthProvider>
			</Layout>
	</React.Fragment>
  );
}

export default App;
